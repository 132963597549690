import { useState, useEffect, useRef, useCallback } from "react"
import { Link, useNavigate } from "react-router-dom"
import { collection, query, where, orderBy, getDocs, limit, startAfter, doc, deleteDoc } from "firebase/firestore"
import { useLongPress } from "use-long-press"
import { Haptics } from "@capacitor/haptics"
import { db } from "../firebaseInit"
import Photo from "../components/Photo"
import { useStore, useUserStore } from "../store"
import LeftArrow from "../components/icons/LeftArrow"
import EmptyIcon from "../components/icons/Empty"
import CheckIcon from "../components/icons/Check"
import SpinnerLight from "../images/spinner-light.gif"

const deletePhoto = id => {
    
    return new Promise(async (resolve, reject) => {
        try {
            await deleteDoc(doc(db, "gallery", id))
            resolve(true)
        }
        catch (err){
            reject(err)
        }
    })

}

const MyPhotos = () => {
    
    const navigate = useNavigate()
    const [ readyToDisplayPhotos, setReadyToDisplayPhotos ] = useState(false)
    const [ currentPhoto, setCurrentPhoto ] = useState(0)
    const [ selectedPhotos, setSelectedPhotos ] = useState([])
    const myPhotos = useStore(state => state.myPhotos)
    const allPhotos = useStore(state => state.photos)
    const setMyPhotos = useStore(state => state.setMyPhotos)
    const setAllPhotos = useStore(state => state.setPhotos)
    const locationQueries = useStore(state => state.locationQueries)
    const somethingIsLoading = useStore(state => state.somethingIsLoading)
    const setSomethingIsLoading = useStore(state => state.setSomethingIsLoading)
    const userData = useUserStore(state => state.userData)
    const canLoadMore = useRef(true)
    const scrollableArea = useRef(null)

    const bindLongPress = useLongPress(async (e, id) => {
        if (selectedPhotos.length > 0) return
        
        setSelectedPhotos([...selectedPhotos,id.context])
        if (window.location.search !== "?long-pressed"){
            navigate("?long-pressed")
        }
        await Haptics.vibrate({duration: 100})
    }, {
        cancelOnMovement: 1
    })

    const onPhotoClick = (id, i) => {
        if (selectedPhotos.length){
            if (selectedPhotos.includes(id)){
                setSelectedPhotos(selectedPhotos.filter(sp => id !== sp))
            }
            else {
                setSelectedPhotos([...selectedPhotos,id])
            }
        }
        else {
            navigate("?photo")
            setCurrentPhoto(i)
        }
    }
    
    const getPhotos = useCallback(async () => {
        if (!userData || myPhotos.loading || !canLoadMore.current) return
        
        setMyPhotos({
            init: true,
            error: null,
            loading: true
        })
        canLoadMore.current = false
        
        try {
            const q = myPhotos.lastItem ? query(
                collection(db, "gallery"),
                where("userId", "==", userData.uid),
                orderBy("iat", "desc"),
                startAfter(myPhotos.lastItem),
                limit(50)
            ) : query(
                collection(db, "gallery"),
                where("userId", "==", userData.uid),
                orderBy("iat", "desc"),
                limit(50)
            )
            const snapshot = await getDocs(q)
            
            if (!snapshot.empty){
                if (snapshot.size >= 50){
                    canLoadMore.current = true
                }
                const newList = []
                snapshot.docs.forEach(d => {
                    const x = myPhotos.data.filter(p => p.id === d.id)
                    if (!x[0]){
                        newList.push(
                            {
                                id: d.id,
                                ...d.data()
                            }
                        )
                    }
                })
                setMyPhotos({
                    loading: false,
                    data: [
                        ...myPhotos.data,
                        ...newList
                    ],
                    lastItem: snapshot.docs[snapshot.docs.length-1]
                })
            }
            else {
                setMyPhotos({
                    loading: false
                })
            }
        }
        catch (err){
            setMyPhotos({
                loading: false,
                setError: "Something is wrong, please try again."
            })
        }
    }, [userData, myPhotos, setMyPhotos])
    
    const retryGettingPhotos = () => {
        canLoadMore.current = true
        getPhotos()
    }

    const deletePhotos = async () => {
        if (somethingIsLoading || selectedPhotos.length < 1 || !userData) return

        setSomethingIsLoading(true)

        try {
            const arr = [...selectedPhotos]
            setSelectedPhotos([])
            setMyPhotos({
                data: myPhotos.data.filter(photo => !arr.includes(photo.id))
            })
            setAllPhotos({
                data: allPhotos.data.filter(photo => !arr.includes(photo.id))
            })
            await Promise.all(arr.map(id => deletePhoto(id)))
            setSomethingIsLoading(false)
        }
        catch {
            setSomethingIsLoading(false)
        }
    }
    
    const onScroll = e => {
        const sh = e.target.scrollHeight
        const ch = e.target.clientHeight
        const st = e.target.scrollTop
        const trigger = ch/3
        const x = sh-(ch+trigger)
        
        if (sh > ch){
            if (x <= st){
                getPhotos()
            }
        }
    }

    useEffect(() => {
        if (!myPhotos.init){
            getPhotos()
        }
    }, [myPhotos.init, getPhotos])

    useEffect(() => {
        if (window.location.search === "?upload-photos" && scrollableArea.current){
            setTimeout(() => {
                if (window.location.search === "?upload-photos" && scrollableArea.current){
                    scrollableArea.current.scrollTo(0,0)
                }
            }, 200)
        }
        
        if (!locationQueries.includes("long-pressed")){
            setSelectedPhotos([])
        }
    }, [locationQueries])
    
    useEffect(() => {
        if (selectedPhotos.length === 0 && window.location.search === "?long-pressed"){
            window.history.back()
        }
    }, [selectedPhotos])

    useEffect(() => {
        setReadyToDisplayPhotos(true)
    }, [])
    
    return (
        <div className={`
            block
            w-full
            h-full
            overflow-hidden
            bg-[#000000]
            absolute
            z-[20]
            top-0
            left-0
            pt-[50px]
        `}>
            {
                (myPhotos.data[currentPhoto] && locationQueries.includes("photo")) ?
                <Photo
                    data={myPhotos}
                    currentPhoto={currentPhoto}
                    setCurrentPhoto={setCurrentPhoto}
                    getMoreData={getPhotos}
                /> : ""
            }
            <div className={`
                block
                w-full
                h-[50px]
                overflow-hidden
                bg-[#181818]
                absolute
                z-[20]
                top-0
                left-0
            `}>
                <div className="
                    block
                    w-[94%]
                    h-full
                    mx-auto
                    relative
                ">
                    <button type="button" className="
                        block
                        w-[50px]
                        h-[50px]
                        active:bg-[rgba(255,255,255,.1)]
                        p-[15px]
                        absolute
                        top-1/2
                        -translate-y-1/2
                        -left-[10px]
                    " onClick={() => window.history.back()}>
                        <LeftArrow color="#ffffff"/>
                    </button>
                    <h2 className="
                        block
                        w-[94%]
                        mx-auto
                        font-defaultRegular
                        text-left
                        text-[#ffffff]
                        text-[18px]
                        2xs:text-[20px]
                        leading-[50px]
                        pl-[30px]
                    ">My Photos</h2>
                    <Link to="?upload-photos" className="
                        inline-block
                        absolute
                        top-1/2
                        -translate-y-1/2
                        right-0
                        leading-[40px]
                        font-defaultRegular
                        text-[#87ceeb]
                        text-[12px]
                        2xs:text-[14px]
                        active:bg-[#222222]
                        px-[10px]
                    ">+ Upload</Link>
                </div>
            </div>
            <div className={`
                block
                w-full
                h-full
                overflow-auto
                ${(myPhotos.loading && myPhotos.data.length) ? "pb-[50px]" : ""}
                relative
            `} ref={scrollableArea} onScroll={onScroll}>
                {
                    selectedPhotos.length > 0 ?
                    <div className="
                        block
                        w-full
                        py-[15px]
                        bg-[#000000]
                        sticky
                        z-[20]
                        top-0
                        left-0
                    ">
                        <div className="
                            block
                            w-[94%]
                            mx-auto
                            text-left
                        ">
                            <button type="button" className="
                                inline-block
                                font-defaultRegular
                                text-left
                                text-[#dd0000]
                                text-[12px]
                                2xs:text-[14px]
                                mr-[15px]
                            " onClick={deletePhotos}>Delete ({selectedPhotos.length})</button>
                        </div>
                    </div> : ""
                }
                {
                    readyToDisplayPhotos ?
                    <div className="
                        grid
                        grid-cols-2
                        2xs:grid-cols-3
                        xs:grid-cols-4
                        md:grid-cols-5
                        gap-[4px]
                        p-[4px]
                        w-full
                        relative
                        z-[10]
                    ">
                        {
                            myPhotos.data.map((photo, i) => {
                                return (
                                    <div
                                        key={photo.id}
                                        className="
                                            w-full
                                            bg-no-repeat
                                            bg-cover
                                            bg-center
                                            square
                                            relative
                                            active:scale-[0.95]
                                            origin-center
                                        "
                                        style={{
                                            backgroundImage: `url(${photo.thumbnail_url || photo.url})`,
                                            backgroundColor: "#191919"
                                        }}
                                        onClick={() => onPhotoClick(photo.id, i)}
                                        {...bindLongPress(photo.id)}
                                    >
                                        {
                                            selectedPhotos.length ?
                                            <div className={`
                                                block
                                                w-[20px]
                                                h-[20px]
                                                rounded-[4px]
                                                ${selectedPhotos.includes(photo.id) ? "bg-[#8a2be2]" : "bg-[rgba(255,255,255,.8)]"}
                                                absolute
                                                bottom-[10px]
                                                right-[10px]
                                                p-[3px]
                                            `}>
                                                {
                                                    selectedPhotos.includes(photo.id) ?
                                                    <CheckIcon color="#ffffff" strokeWidth={80}/> : ""
                                                }
                                            </div> : ""
                                        }
                                    </div>
                                )
                            })
                        }
                    </div> : ""
                }
                {
                    (myPhotos.loading || !readyToDisplayPhotos) ?
                    <div className="
                        block
                        w-full
                        py-[20px]
                        relative
                        z-[10]
                    ">
                        <img src={SpinnerLight} alt="" className="
                            block
                            w-[40px]
                            mx-auto
                        "/>
                    </div> : ""
                }
                {
                    myPhotos.error ?
                    <div className="
                        block
                        w-full
                        py-[10px]
                        px-[3%]
                        bg-[#dd0000]
                        relative
                        z-[10]
                    ">
                        <div className="
                            block
                            w-full
                            font-defaultRegular
                            text-[#ffffff]
                            text-[12px]
                            2xs:text-[14px]
                            text-left
                            pr-[100px]
                            relative
                        ">
                            {myPhotos.error.message}
                            <button type="button" className="
                                block
                                w-[80px]
                                h-[30px]
                                absolute
                                top-1/2
                                -translate-y-1/2
                                right-0
                                bg-[#ffffff]
                                rounded-[4px]
                                font-defaultRegular
                                text-center
                                text-[#111111]
                                text-[10px]
                                2xs:text-[12px]
                                active:opacity-[.8]
                            " onClick={retryGettingPhotos}>Retry</button>
                        </div>
                    </div> : ""
                }
                {
                    (myPhotos.init && !myPhotos.loading && !myPhotos.error && myPhotos.data.length === 0) ?
                    <div className="
                        block
                        w-[94%]
                        max-w-[1000px]
                        mx-auto
                        py-[50px]
                        relative
                        z-[10]
                    ">
                        <div className="
                            block
                            w-[50px]
                            h-[50px]
                            mx-auto
                            mb-[10px]
                        ">
                            <EmptyIcon color="#888888"/>
                        </div>
                        <div className="
                            block
                            w-full
                            font-defaultRegular
                            text-[#ffffff]
                            text-[12px]
                            2xs:text-[14px]
                            text-center
                        ">You haven't upload any photos yet.</div>
                    </div> : ""
                }
            </div>
        </div>
    )

}

export default MyPhotos