import { useState, useEffect, useRef, useCallback } from "react"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { collection, query, orderBy, getDocs, limit, startAfter } from "firebase/firestore"
import { db } from "../firebaseInit"
import Photo from "../components/Photo"
import { useStore } from "../store"
import LeftArrow from "../components/icons/LeftArrow"
import EmptyIcon from "../components/icons/Empty"
import SpinnerLight from "../images/spinner-light.gif"

const Gallery = () => {
    
    const location = useLocation()
    const navigate = useNavigate()
    const navigateRef = useRef(navigate)
    useEffect(() => {
        navigateRef.current = navigate
    }, [navigate])
    const [ readyToDisplayPhotos, setReadyToDisplayPhotos ] = useState(false)
    const [ currentPhoto, setCurrentPhoto ] = useState(0)
    const photos = useStore(state => state.photos)
    const setPhotos = useStore(state => state.setPhotos)
    const locationQueries = useStore(state => state.locationQueries)
    const canLoadMore = useRef(true)
    const scrollableArea = useRef(null)

    const onPhotoClick = i => {
        navigate("?photo")
        setCurrentPhoto(i)
    }
    
    const getPhotos = useCallback(async () => {
        if (photos.loading || !canLoadMore.current) return
        
        setPhotos({
            init: true,
            error: null,
            loading: true
        })
        canLoadMore.current = false
        
        try {
            const q = photos.lastItem ? query(
                collection(db, "gallery"),
                orderBy("iat", "desc"),
                startAfter(photos.lastItem),
                limit(50)
            ) : query(
                collection(db, "gallery"),
                orderBy("iat", "desc"),
                limit(50)
            )
            const snapshot = await getDocs(q)
            
            if (!snapshot.empty){
                if (snapshot.size >= 50){
                    canLoadMore.current = true
                }
                const newList = []
                snapshot.docs.forEach(d => {
                    const x = photos.data.filter(p => p.id === d.id)
                    if (!x[0]){
                        newList.push(
                            {
                                id: d.id,
                                ...d.data()
                            }
                        )
                    }
                })
                setPhotos({
                    loading: false,
                    data: [
                        ...photos.data,
                        ...newList
                    ],
                    lastItem: snapshot.docs[snapshot.docs.length-1]
                })
            }
            else {
                setPhotos({
                    loading: false
                })
            }
        }
        catch (err){
            setPhotos({
                loading: false,
                setError: "Something is wrong, please try again."
            })
        }
    }, [photos, setPhotos])
    
    const retryGettingPhotos = () => {
        canLoadMore.current = true
        getPhotos()
    }
    
    const onScroll = e => {
        const sh = e.target.scrollHeight
        const ch = e.target.clientHeight
        const st = e.target.scrollTop
        const trigger = ch/3
        const x = sh-(ch+trigger)
        
        if (sh > ch){
            if (x <= st){
                getPhotos()
            }
        }
    }

    useEffect(() => {
        if (!photos.init){
            getPhotos()
        }
    }, [photos.init, getPhotos])

    useEffect(() => {
        if (window.location.search === "?upload-photos" && scrollableArea.current){
            setTimeout(() => {
                if (window.location.search === "?upload-photos" && scrollableArea.current){
                    scrollableArea.current.scrollTo(0,0)
                }
            }, 200)
        }
    }, [locationQueries])

    useEffect(() => {
        setReadyToDisplayPhotos(true)
    }, [])

    useEffect(() => {
        if (window.location.search.startsWith("?go-to-photo=") && navigateRef.current){
            const index = Number(window.location.search.replace("?go-to-photo=", ""))
            
            if ((index || index === 0) && index > -1 && index < 15){
                setCurrentPhoto(index)
                navigateRef.current("/gallery?photo", {replace: true})
            }
            else {
                navigateRef.current("/gallery", {replace: true})
            }
        }
    }, [locationQueries])
    
    return (
        <>
            {
                location.pathname === "/gallery" ?
                <div className="
                    block
                    w-full
                    h-full
                    overflow-hidden
                    bg-[#000000]
                    absolute
                    z-[20]
                    top-0
                    left-0
                    pt-[50px]
                ">
                    {
                        (photos.data[currentPhoto] && locationQueries.includes("photo")) ?
                        <Photo
                            data={photos}
                            currentPhoto={currentPhoto}
                            setCurrentPhoto={setCurrentPhoto}
                            getMoreData={getPhotos}
                        /> : ""
                    }
                    <div className="
                        block
                        w-full
                        h-[50px]
                        overflow-hidden
                        bg-[#181818]
                        absolute
                        z-[20]
                        top-0
                        left-0
                    ">
                        <div className="
                            block
                            w-[94%]
                            h-full
                            mx-auto
                            relative
                        ">
                            <button type="button" className="
                                block
                                w-[50px]
                                h-[50px]
                                active:bg-[rgba(255,255,255,.1)]
                                p-[15px]
                                absolute
                                top-1/2
                                -translate-y-1/2
                                -left-[10px]
                            " onClick={() => window.history.back()}>
                                <LeftArrow color="#ffffff"/>
                            </button>
                            <h2 className="
                                block
                                w-[94%]
                                mx-auto
                                font-defaultRegular
                                text-left
                                text-[#ffffff]
                                text-[18px]
                                2xs:text-[20px]
                                leading-[50px]
                                pl-[30px]
                            ">Photo Gallery</h2>
                            <Link to="?upload-photos" className="
                                inline-block
                                absolute
                                top-1/2
                                -translate-y-1/2
                                right-0
                                leading-[40px]
                                font-defaultRegular
                                text-[#87ceeb]
                                text-[12px]
                                2xs:text-[14px]
                                active:bg-[#222222]
                                px-[10px]
                            ">+ Upload</Link>
                        </div>
                    </div>
                    <div className={`
                        block
                        w-full
                        h-full
                        overflow-auto
                        ${(photos.loading && photos.data.length) ? "pb-[50px]" : ""}
                        relative
                    `} ref={scrollableArea} onScroll={onScroll}>
                        {
                            readyToDisplayPhotos ?
                            <div className="
                                grid
                                grid-cols-2
                                2xs:grid-cols-3
                                xs:grid-cols-4
                                md:grid-cols-5
                                gap-[4px]
                                p-[4px]
                                w-full
                                relative
                                z-[10]
                            ">
                                {
                                    photos.data.map((photo, i) => {
                                        return (
                                            <div
                                                key={photo.id}
                                                className="
                                                    w-full
                                                    bg-no-repeat
                                                    bg-cover
                                                    bg-center
                                                    square
                                                    relative
                                                "
                                                style={{
                                                    backgroundImage: `url(${photo.thumbnail_url || photo.url})`,
                                                    backgroundColor: "#191919"
                                                }}
                                                onClick={() => onPhotoClick(i)}
                                            ></div>
                                        )
                                    })
                                }
                            </div> : ""
                        }
                        {
                            (photos.loading || !readyToDisplayPhotos) ?
                            <div className="
                                block
                                w-full
                                py-[20px]
                                relative
                                z-[10]
                            ">
                                <img src={SpinnerLight} alt="" className="
                                    block
                                    w-[40px]
                                    mx-auto
                                "/>
                            </div> : ""
                        }
                        {
                            photos.error ?
                            <div className="
                                block
                                w-full
                                py-[10px]
                                px-[3%]
                                bg-[#dd0000]
                                relative
                                z-[10]
                            ">
                                <div className="
                                    block
                                    w-full
                                    font-defaultRegular
                                    text-[#ffffff]
                                    text-[12px]
                                    2xs:text-[14px]
                                    text-left
                                    pr-[100px]
                                    relative
                                ">
                                    {photos.error.message}
                                    <button type="button" className="
                                        block
                                        w-[80px]
                                        h-[30px]
                                        absolute
                                        top-1/2
                                        -translate-y-1/2
                                        right-0
                                        bg-[#ffffff]
                                        rounded-[4px]
                                        font-defaultRegular
                                        text-center
                                        text-[#111111]
                                        text-[10px]
                                        2xs:text-[12px]
                                        active:opacity-[.8]
                                    " onClick={retryGettingPhotos}>Retry</button>
                                </div>
                            </div> : ""
                        }
                        {
                            (photos.init && !photos.loading && !photos.error && photos.data.length === 0) ?
                            <div className="
                                block
                                w-[94%]
                                max-w-[1000px]
                                mx-auto
                                py-[50px]
                                relative
                                z-[10]
                            ">
                                <div className="
                                    block
                                    w-[50px]
                                    h-[50px]
                                    mx-auto
                                    mb-[10px]
                                ">
                                    <EmptyIcon color="#888888"/>
                                </div>
                                <div className="
                                    block
                                    w-full
                                    font-defaultRegular
                                    text-[#ffffff]
                                    text-[12px]
                                    2xs:text-[14px]
                                    text-center
                                ">Nothing to see yet.</div>
                            </div> : ""
                        }
                    </div>
                </div> : ""
            }
        </>
    )

}

export default Gallery