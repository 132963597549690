import { useEffect, useRef } from "react"
import { db } from "../../firebaseInit"
import { onSnapshot, collection, query, where, limit } from "firebase/firestore"
import { useStore } from "../../store"

const HolidaysWatcher = () => {

    const unsubscribe = useRef()
    const setHolidays = useStore(state => state.setHolidays)
    
    useEffect(() => {
        if (!unsubscribe.current){
            try {
                const startOfToday = new Date().setHours(0,0,0,0)
                const q = query(collection(db, "holidays"), where("millis", ">=", startOfToday), limit(100))
                unsubscribe.current = onSnapshot(q, snapshot => {
                    if (snapshot.empty){
                        setHolidays([])
                    }
                    else {
                        const days = []
                        snapshot.forEach(doc => days.push(doc.id))
                        setHolidays(days)
                    }
                })
            }
            catch {}
        }
    }, [setHolidays])
    
    return (
        <div className="hidden"></div>
    )

}

export default HolidaysWatcher