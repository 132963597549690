import { Routes, Route } from "react-router-dom"
import { useStore, useUserStore, useAssetsStore } from "./store"

// import pages
import Base from "./components/Base"
import Signin from "./pages/Signin"
import Menu from "./pages/Menu"
import History from "./pages/History"
import MyPhotos from "./pages/MyPhotos"
import Booking from "./pages/Booking"
import Checkout from "./pages/Checkout"
import Gallery from "./pages/Gallery"

// import components
import Offline from "./components/Offline"
import DateSelector from "./components/DateSelector"
import PhotosUploader from "./components/PhotosUploader"

// import images
import SpinnerLight from "./images/spinner-light.gif"

// import background components
import AuthHandler from "./background-components/firebase/AuthHandler"
import NetworkHandler from "./background-components/NetworkHandler"
import StatusBarHandler from "./background-components/StatusBarHandler"
import LocationQueryWatcher from "./background-components/LocationQueryWatcher"
import AssetsWatcher from "./background-components/firebase/AssetsWatcher"
import HolidaysWatcher from "./background-components/firebase/HolidaysWatcher"
import SelectedDateWatcher from "./background-components/SelectedDateWatcher"
import TotalPriceCalculator from "./background-components/TotalPriceCalculator"

const App = () => {
	
	const networkConnected = useStore(state => state.networkConnected)
	const somethingIsLoading = useStore(state => state.somethingIsLoading)
	const userData = useUserStore(state => state.userData)
	const appAssets = useAssetsStore(state => state.data)
	
	return (
		<div id="app" className={`
			flex
			w-full
			h-full
			fixed
			top-0
			left-0
			overflow-hidden
			${!networkConnected ? "pt-[30px]" : ""}
			duration-[.2s]
			ease-in-out
		`}>
			<Offline/>
			{
				(somethingIsLoading || !appAssets) ?
				<div className="
					block
					w-full
					h-full
					bg-[rgba(0,0,0,.9)]
					absolute
					top-0
					left-0
					z-[100]
				">
					<img src={SpinnerLight} alt="" className="
						block
						w-[40px]
						absolute
						top-1/2
						-translate-y-1/2
						left-1/2
						-translate-x-1/2
					"/>
				</div> : ""
			}
			<AssetsWatcher/>
			{
				appAssets ?
				<div className="
					block
					w-full
					max-w-[1200px]
					h-full
					overflow-hidden
					relative
					z-[10]
					m-auto
				">
					<Base/>
					<Menu/>
					<DateSelector disablePastMonths={true}/>
					<PhotosUploader/>
					
					<AuthHandler/>
					<NetworkHandler/>
					<StatusBarHandler/>
					<LocationQueryWatcher/>
					<HolidaysWatcher/>
					<SelectedDateWatcher/>
					<TotalPriceCalculator/>
					
					<Routes>
						<Route exact path="/" element={<Gallery/>}/>
						<Route path="/signin" element={<Signin/>}/>
						<Route path="/menu" element={<div className="hidden"></div>}/>
						<Route path="/history" element={userData ? <History/> : <Signin/>}/>
						<Route path="/my-photos" element={userData ? <MyPhotos/> : <Signin/>}/>
						<Route path="/booking" element={<Booking/>}/>
						<Route path="/checkout" element={userData ? <Checkout/> : <Signin/>}/>
						<Route path="/gallery" element={<Gallery/>}/>
					</Routes>
				</div> : ""
			}
		</div>
	)

}

export default App