import { useState, useEffect, useRef } from "react"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import TextareaAutosize from "react-textarea-autosize"
import { updateProfile } from "firebase/auth"
import { Haptics } from "@capacitor/haptics"
import dayjs from "dayjs"
import isToday from "dayjs/plugin/isToday"
import isTomorrow from "dayjs/plugin/isTomorrow"
import { fbAuth } from "../firebaseInit"
import { useStore, useUserStore, useAssetsStore } from "../store"
import hourConverter from "../lib/24HourTo12Hour"
import LeftArrow from "../components/icons/LeftArrow"
import CheckIcon from "../components/icons/Check"

dayjs.extend(isToday)
dayjs.extend(isTomorrow)

const Checkout = () => {

    const navigate = useNavigate()
    const [ name, setName ] = useState("")
    const [ error, setError ] = useState(null)
    const [ hourToUnselect, setHourToUnselect ] = useState("")
    const [ prefilled, setPrefilled ] = useState(false)
    const selectedHours = useStore(state => state.selectedHours)
    const setSelectedHours = useStore(state => state.setSelectedHours)
    const userData = useUserStore(state => state.userData)
    const appAssets = useAssetsStore(state => state.data)
    const totalPrice = useStore(state => state.totalPrice)
    const somethingIsLoading = useStore(state => state.somethingIsLoading)
    const setSomethingIsLoading = useStore(state => state.setSomethingIsLoading)
    const scrollableArea = useRef(null)
    
    const onNameInputChange = e => {
        const n = e.target.value.replace(/(\r\n|\n|\r)/gm, "")
        if (n.length <= 128){
            setName(n)
        }
    }

    const unselectHour = hourId => {
        if (!hourToUnselect){
            setHourToUnselect(hourId)
        }
    }

    const bookNow = async () => {
        if (somethingIsLoading || !userData || !appAssets || !appAssets.apiBaseUrl || selectedHours.length < 1) return

        if (!name){
            await Haptics.notification({
                type: "ERROR"
            })
            return setError({
                message: "Please enter your name."
            })
        }
        else if (name.length > 128){
            await Haptics.notification({
                type: "ERROR"
            })
            return setError({
                message: "Name too long."
            })
        }
        
        setSomethingIsLoading(true)
        setError(null)
        
        try {
            if (name !== userData.displayName){
                await updateProfile(fbAuth.currentUser, {
                    displayName: name
                })
            }
            
            await axios.post(`${appAssets.apiBaseUrl}/book-hours`, {
                name,
                hours: selectedHours
            }, {
                headers: {
                    Authorization: `Bearer ${userData.accessToken}`
                }
            })

            if (window.location.pathname === "/checkout"){
                navigate("/history", {replace: true})
            }
            setTimeout(() => {
                setSelectedHours([])
                setSomethingIsLoading(false)
            }, 400)
        }
        catch (err){
            setSomethingIsLoading(false)
            setError({
                message: (err && err.response && err.response.data && err.response.data.message) ? err.response.data.message : "Something went wrong, please try again."
            })
        }
    }
    
    useEffect(() => {
        if (!prefilled && userData){
            setPrefilled(true)
            if (userData.displayName){
                setName(userData.displayName)
            }
        }
    }, [userData, prefilled])

    useEffect(() => {
        if (selectedHours.length < 1 && !somethingIsLoading){
            window.history.back()
        }
    }, [selectedHours, somethingIsLoading])
    
    useEffect(() => {
        if (hourToUnselect){
            setTimeout(() => {
                if (hourToUnselect && window.location.pathname === "/checkout"){
                    const newList = selectedHours.filter(hid => hid !== hourToUnselect)
                    setHourToUnselect("")
                    setSelectedHours(Array.from(new Set(newList)))
                }
            }, 200)
        }
    }, [hourToUnselect, selectedHours, setSelectedHours])

    useEffect(() => {
        if (error && scrollableArea.current){
            scrollableArea.current.scrollTo(0,0)
        }
    }, [error])
    
    return (
        <div className={`
            block
            w-full
            h-full
            overflow-hidden
            bg-[#000000]
            absolute
            z-[20]
            top-0
            left-0
            pt-[50px]
            ${(selectedHours.length > 0 && name) ? "pb-[120px] 2xs:pb-[130px]" : ""}
            duration-[.2s]
            ease-in-out
        `}>
            <div className={`
                block
                w-full
                h-[50px]
                overflow-hidden
                bg-[#181818]
                absolute
                z-[20]
                top-0
                left-0
            `}>
                <div className="
                    block
                    w-[94%]
                    h-full
                    mx-auto
                    relative
                    pl-[35px]
                ">
                    <button type="button" className="
                        block
                        w-[50px]
                        h-[50px]
                        active:bg-[rgba(255,255,255,.1)]
                        p-[15px]
                        absolute
                        top-1/2
                        -translate-y-1/2
                        -left-[10px]
                    " onClick={() => window.history.back()}>
                        <LeftArrow color="#ffffff"/>
                    </button>
                    <h2 className="
                        block
                        w-[94%]
                        mx-auto
                        font-defaultRegular
                        text-left
                        text-[#ffffff]
                        text-[18px]
                        2xs:text-[20px]
                        leading-[50px]
                    ">Checkout</h2>
                </div>
            </div>
            <div className="
                block
                w-full
                h-full
                overflow-auto
                scrollbar-hidden
            " ref={scrollableArea}>
                {
                    error ?
                    <div className="
                        block
                        w-full
                        py-[15px]
                        bg-[#ca3a3a]
                    ">
                        <div className="
                            block
                            w-[94%]
                            mx-auto
                            font-defaultBold
                            text-left
                            text-[#ffffff]
                            text-[14px]
                            2xs:text-[16px]
                        ">{error.message}</div>
                    </div> : ""
                }
                <div className="
                    block
                    w-full
                    border-b-[10px]
                    border-solid
                    border-[#191919]
                    pt-[40px]
                    pb-[30px]
                ">
                    <div className="
                        block
                        w-[94%]
                        mx-auto
                        relative
                        border
                        border-solid
                        border-[#333333]
                        bg-[#191919]
                        py-[10px]
                        rounded-[10px]
                        mb-[30px]
                    ">
                        <label htmlFor="checkout-name-input" className="
                            block
                            w-full
                            font-defaultRegular
                            text-left
                            text-[#888888]
                            text-[11px]
                            2xs:text-[12px]
                            px-[10px]
                            mb-[5px]
                        ">Name *</label>
                        <TextareaAutosize
                            id="checkout-name-input"
                            placeholder="Enter Your Name"
                            name="name"
                            value={name}
                            onChange={onNameInputChange}
                            minRows={1}
                            maxRows={10}
                            className="
                                block
                                w-full
                                font-defaultRegular
                                text-left
                                text-[#ffffff]
                                text-[18px]
                                2xs:text-[20px]
                                resize-none
                                relative
                                z-[10]
                                px-[10px]
                            "
                        />
                        <div className="
                            block
                            w-[40px]
                            font-defaultRegular
                            text-center
                            text-[#888888]
                            text-[11px]
                            2xs:text-[12px]
                            absolute
                            z-[20]
                            top-[12px]
                            right-0
                        ">{128-name.length}</div>
                    </div>
                    <div className="
                        block
                        w-[94%]
                        mx-auto
                        relative
                    ">
                        <div className="
                            block
                            w-full
                            font-defaultRegular
                            text-left
                            text-[#888888]
                            text-[11px]
                            2xs:text-[12px]
                        ">Phone Number</div>
                        <div className="
                            block
                            w-full
                            font-defaultRegular
                            text-left
                            text-[#888888]
                            text-[18px]
                            2xs:text-[20px]
                        ">{userData.phoneNumber}</div>
                    </div>
                </div>
                <div className="
                    block
                    w-full
                    py-[30px]
                ">
                    <div className="
                        block
                        w-[94%]
                        mx-auto
                    ">
                        <div className="
                            block
                            w-full
                            font-defaultRegular
                            text-left
                            text-[#ffffff]
                            text-[18px]
                            2xs:text-[20px]
                            mb-[30px]
                        ">{selectedHours.length} Hour{selectedHours.length > 1 ? "s" : ""} Selected</div>
                        {
                            selectedHours.map(sh => {
                                const time = sh.split("-")
                                const hour = Number(time[0])
                                const dateOfMonth = Number(time[1])
                                const month = Number(time[2])-1
                                const year = Number(time[3])
                                const twelveHourFormat = hourConverter(hour)
                                const pricing = appAssets.pricing.hours.filter(h => h.hour === hour)[0]
                                const millis = new Date(new Date(new Date().setHours(hour,0,0,0)).setMonth(month,dateOfMonth)).setFullYear(year)
                                if (!pricing) return ""
                                
                                const price = pricing.price
                                
                                return (
                                    <div key={sh} className={`
                                        block
                                        w-full
                                        ${hourToUnselect !== sh ? "h-[55px] 2xs:h-[60px]" : "h-0"}
                                        bg-[rgba(255,255,255,.08)]
                                        rounded-[10px]
                                        mb-[10px]
                                        relative
                                        overflow-hidden
                                        duration-[.2s]
                                        ease-in-out
                                    `}>
                                        <button type="button" className={`
                                            block
                                            w-[20px]
                                            h-[20px]
                                            ${hourToUnselect !== sh ? "bg-[#8a2be2] border-[#8a2be2]" : "bg-[rgba(255,255,255,.1)] border-[rgba(255,255,255,.2)]"}
                                            absolute
                                            z-[20]
                                            top-1/2
                                            -translate-y-1/2
                                            left-[12px]
                                            rounded-[4px]
                                            p-[3px]
                                        `} onClick={() => unselectHour(sh)}>{hourToUnselect !== sh ? <CheckIcon color="#ffffff" strokeWidth="80"/> : ""}</button>
                                        <div className={`
                                            block
                                            w-full
                                            absolute
                                            z-[10]
                                            top-1/2
                                            -translate-y-1/2
                                            left-0
                                            pl-[50px]
                                            pr-[80px]
                                        `}>
                                            <div className="
                                                block
                                                2xs:inline-block
                                                align-middle
                                                w-full
                                                2xs:w-auto
                                                font-defaultBlack
                                                text-left
                                                text-[#ffffff]
                                                text-[18px]
                                                2xs:text-[20px]
                                                uppercase
                                                overflow-hidden
                                                whitespace-nowrap
                                                text-ellipsis
                                            ">
                                                {twelveHourFormat.hour < 10 ? "0" : ""}
                                                {twelveHourFormat.hour}
                                                <span className="
                                                    text-[60%]
                                                    font-defaultRegular
                                                    ml-[3px]
                                                ">{twelveHourFormat.ampm}</span>
                                                <span className="
                                                    hidden
                                                    2xs:inline
                                                ">,</span>
                                            </div>
                                            <div className="
                                                block
                                                2xs:inline-block
                                                align-middle
                                                w-full
                                                2xs:w-auto
                                                2xs:ml-[5px]
                                                font-defaultRegular
                                                text-left
                                                text-[#888888]
                                                text-[10px]
                                                2xs:text-[12px]
                                                overflow-hidden
                                                whitespace-nowrap
                                                text-ellipsis
                                            ">{dayjs(millis).isToday() ? "Today" : dayjs(millis).isTomorrow() ? "Tomorrow" : dayjs(millis).format("LL")}</div>
                                            <div className="
                                                block
                                                w-[80px]
                                                absolute
                                                top-1/2
                                                -translate-y-1/2
                                                right-0
                                                font-defaultRegular
                                                text-[#87ceeb]
                                                text-[10px]
                                                2xs:text-[12px]
                                                text-center
                                            ">₹{price}</div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        <div className="
                            block
                            w-full
                            text-right
                            font-defaultBold
                            text-[#ffffff]
                            text-[14px]
                            2xs:text-[16px]
                            px-[15px]
                            mt-[15px]
                        ">Total: ₹{totalPrice}</div>
                        <div className="
                            block
                            w-full
                            text-right
                            font-defaultRegular
                            text-[#888888]
                            text-[10px]
                            2xs:text-[12px]
                            px-[15px]
                        ">Pay on Arrival</div>
                    </div>
                </div>
            </div>
            <div className={`
                block
                w-full
                h-[120px]
                2xs:h-[130px]
                bg-[#000000]
                absolute
                z-[20]
                ${(selectedHours.length > 0 && name) ? "bottom-0" : "-bottom-[150px]"}
                left-0
                duration-[.2s]
                ease-in-out
            `}>
                <div className="
                    block
                    w-[94%]
                    mx-auto
                    py-[10px]
                ">
                    <button type="button" className="
                        block
                        w-full
                        h-[55px]
                        2xs:h-[60px]
                        bg-[#6c09c9]
                        active:bg-[#8a2be2]
                        rounded-[10px]
                        font-defaultRegular
                        text-center
                        text-[14px]
                        2xs:text-[16px]
                        text-[#ffffff]
                        uppercase
                    " onClick={bookNow}>Book Now</button>
                    <p className="
                        block
                        w-full
                        font-defaultRegular
                        text-left
                        text-[11px]
                        2xs:text-[12px]
                        leading-[15px]
                        2xs:leading-[16px]
                        text-[#888888]
                        mt-[10px]
                    ">By continuing you agree to our <a href="https://tos.false9saikot.in" target="_blank" rel="noopener noreferrer" className="text-[#87ceeb] underline">terms of service</a>.</p>
                </div>
            </div>
        </div>
    )

}

export default Checkout