const Icon = ({color}) => {

    return (
        <svg viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-full">
            <path fillRule="evenodd" clipRule="evenodd" d="M407.384 11.4291C398.73 15.0134 390.868 20.267 384.245 26.8899L318.38 92.7548L419.056 193.809L485.11 127.755C491.733 121.132 496.987 113.27 500.571 104.616C504.155 95.9632 506 86.6887 506 77.3225C506 67.9563 504.155 58.6818 500.571 50.0286C496.987 41.3753 491.733 33.5128 485.11 26.8899C478.487 20.267 470.625 15.0134 461.971 11.4291C453.318 7.84481 444.044 6 434.677 6C425.311 6 416.037 7.84481 407.384 11.4291ZM397.843 215.023L297.167 113.968L43.8245 367.31C43.8245 367.31 -7.49997 493.5 6.00001 506C19.5 518.5 144.69 468.176 144.69 468.176L397.843 215.023Z" fill={color || "#111111"}/>
        </svg>
    )

}

export default Icon