import { useState, useEffect, useRef } from "react"
import { Haptics } from "@capacitor/haptics"
import { getStorage, ref, uploadBytes } from "firebase/storage"
import { useStore, useAssetsStore, useUserStore } from "../store"
import genUuid from "../lib/genUuid"
import readFileAsDataUrl from "../lib/readFileAsDataUrl"
import LeftArrow from "./icons/LeftArrow"
import XIcon from "./icons/XIcon"
import SpinnerLight from "../images/spinner-light.gif"

const uploadPhoto = async (userId, file) => {
    const storage = getStorage()
    const filePath = `gallery/${genUuid(16)}`
    const storageRef = ref(storage, filePath)
    const customMetadata = {
        doc_id: genUuid(20),
        userId,
        originalFilePath: filePath
    }
    
    const snapshot = await uploadBytes(storageRef, file, {customMetadata})

    return snapshot
}

const PhotosUploader = () => {
    
    const [ photos, setPhotos ] = useState([])
    const [ totalSize, setTotalSize ] = useState({
        bytes: 0,
        megaBytes: "0"
    })
    const [ removedPhotos, setRemovedPhotos ] = useState([])
    const [ loading, setLoading ] = useState("")
    const [ error, setError ] = useState(null)
    const locationQueries = useStore(state => state.locationQueries)
    const myPhotos = useStore(state => state.myPhotos)
    const allPhotos = useStore(state => state.photos)
    const setMyPhotos = useStore(state => state.setMyPhotos)
    const setAllPhotos = useStore(state => state.setPhotos)
    const appAssets = useAssetsStore(state => state.data)
    const userData = useUserStore(state => state.userData)
    const inputRef = useRef()

    const onInputChange = async e => {
        e.preventDefault()
        setLoading("Please wait...")

        try {
            if (e.target.files.length > 0){
                const files = [...e.target.files]
                const dataUrls = await Promise.all(files.map(file => readFileAsDataUrl(file)))
                const newPhotosList = [...photos]
                files.forEach((file, i) => {
                    newPhotosList.unshift({
                        file,
                        preview_url: dataUrls[i],
                        id: genUuid(10)
                    })
                })
                
                setPhotos(newPhotosList)
            }
            
            setLoading("")
        }
        catch {
            setLoading("")
        }
    }
    
    const onBrowseBtnClick = async () => {
        if (inputRef.current){
            inputRef.current.value = null
            inputRef.current.click()
        }
    }

    const removePhoto = id => {
        setRemovedPhotos([...removedPhotos,id])
    }

    const removeAll = () => {
        setPhotos([])
        setRemovedPhotos([])
    }

    const uploadPhotos = () => {
        if (loading || !userData || !appAssets || photos.length === 0 || totalSize.bytes > 100000000) return
        
        setError(null)
        setLoading("Uploading...")
        
        setTimeout(async () => {
            try {
                const uploadedPhotos = await Promise.all(photos.map(photo => uploadPhoto(userData.uid, photo.file)))
                
                setLoading("")

                const newlyUploadedPhotos = []
                uploadedPhotos.forEach((photo, i) => {
                    newlyUploadedPhotos.push({
                        id: photo.metadata.customMetadata.doc_id,
                        bucket: photo.metadata.bucket,
                        iat: Date.now(),
                        path: `${photo.metadata.customMetadata.originalFilePath}_4000x4000`,
                        thumbnail_path: `${photo.metadata.customMetadata.originalFilePath}_200x200`,
                        url: photos[i].preview_url,
                        userId: userData.uid
                    })
                })
                
                setMyPhotos({
                    data: [
                        ...newlyUploadedPhotos,
                        ...myPhotos.data
                    ]
                })
                setAllPhotos({
                    data: [
                        ...newlyUploadedPhotos,
                        ...allPhotos.data
                    ]
                })
                
                setPhotos([])
                if (window.location.search === "?upload-photos"){
                    window.history.back()
                }
            }
            catch (err){
                setLoading("")
                setError({
                    message: (err && err.response && err.response.data && err.response.data.message) ? err.response.data.message : "Something went wrong, please try again."
                })
                await Haptics.notification({
                    type: "ERROR"
                })
            }
        }, 200)
    }

    useEffect(() => {
        let totalBytes = 0
        photos.forEach(photo => totalBytes += photo.file.size)
        setTotalSize({
            bytes: totalBytes,
            megaBytes: ((totalBytes/1000)/1000).toFixed(2)
        })
    }, [photos])

    useEffect(() => {
        if (removedPhotos.length){
            const newPhotosList = photos.filter(photo => !removedPhotos.includes(photo.id))
            setRemovedPhotos([])
            setPhotos(newPhotosList)
        }
    }, [removedPhotos, photos])
    
    return (
        <div className={`
            block
            w-full
            h-full
            overflow-hidden
            bg-[#000000]
            absolute
            z-[30]
            ${locationQueries.includes("upload-photos") ? "top-0" : "top-[110%]"}
            left-0
            pt-[50px]
            ${photos.length > 0 ? "pb-[100px]" : ""}
            duration-[.2s]
            ease-in-out
        `}>
            <input
				type="file"
                accept="image/*"
                className="hidden"
                onChange={onInputChange}
                ref={inputRef}
                multiple={true}
            />
            {
                loading ?
                <div className="
                    flex
                    w-full
                    h-full
                    absolute
                    z-[40]
                    top-0
                    left-0
                    bg-[rgba(0,0,0,.9)]
                ">
                    <div className="
                        block
                        w-[94%]
                        max-w-[500px]
                        m-auto
                    ">
                        <img src={SpinnerLight} alt="" className="
                            block
                            w-[40px]
                            mx-auto
                            mb-[10px]
                        "/>
                        <div className="
                            block
                            w-full
                            font-defaultRegular
                            text-center
                            text-[#ffffff]
                            text-[12px]
                            2xs:text-[14px]
                        ">{loading}</div>
                    </div>
                </div> : ""
            }
            <div className={`
                block
                w-full
                h-[50px]
                overflow-hidden
                bg-[#181818]
                absolute
                z-[20]
                top-0
                left-0
            `}>
                <div className="
                    block
                    w-[94%]
                    h-full
                    mx-auto
                    relative
                ">
                    <button type="button" className="
                        block
                        w-[50px]
                        h-[50px]
                        active:bg-[rgba(255,255,255,.1)]
                        p-[15px]
                        absolute
                        top-1/2
                        -translate-y-1/2
                        -left-[10px]
                    " onClick={() => window.history.back()}>
                        <LeftArrow color="#ffffff"/>
                    </button>
                    <h2 className="
                        block
                        w-[94%]
                        mx-auto
                        font-defaultBold
                        text-left
                        text-[#ffffff]
                        text-[12px]
                        2xs:text-[14px]
                        leading-[50px]
                        pl-[30px]
                    ">
                        {
                            totalSize.megaBytes.split(".")[0]
                        }{
                            totalSize.bytes > 0 ?
                            <span className="
                                font-defaultRegular
                                text-[80%]
                            ">.{totalSize.megaBytes.split(".")[1]}</span> : ""
                        } /
                        100 <span className="
                            font-defaultRegular
                            text-[80%]
                        ">Mb</span>
                    </h2>
                    {
                        photos.length > 0 ?
                        <button type="button" className="
                            inline-block
                            h-[40px]
                            absolute
                            top-1/2
                            -translate-y-1/2
                            right-0
                            font-defaultRegular
                            text-[#87ceeb]
                            text-[12px]
                            2xs:text-[14px]
                            active:bg-[#222222]
                            px-[10px]
                        " onClick={onBrowseBtnClick}>+ Add Photos</button> : ""
                    }
                </div>
            </div>
            <div className="
                block
                w-full
                h-full
                overflow-auto
                relative
            ">
                {
                    photos.length < 1 ?
                    <button type="button" className="
                        block
                        w-[150px]
                        h-[150px]
                        absolute
                        top-1/2
                        left-1/2
                        -translate-y-1/2
                        -translate-x-1/2
                        border-[4px]
                        border-solid
                        border-[#191919]
                        rounded-[20px]
                        pb-[10px]
                        active:bg-[#191919]
                    " onClick={onBrowseBtnClick}>
                        <div className="
                            block
                            w-full
                            font-defaultRegular
                            text-center
                            text-[#444444]
                            text-[120px]
                            leading-[60px]
                            mb-[15px]
                        ">+</div>
                        <div className="
                            block
                            w-full
                            font-defaultRegular
                            text-center
                            text-[#444444]
                            text-[12px]
                            2xs:text-[14px]
                            uppercase
                        ">Browse</div>
                    </button> : ""
                }
                {
                    error ?
                    <div className="
                        block
                        w-full
                        py-[15px]
                        px-[3%]
                        bg-[#cd5c5c]
                        font-defaultRegular
                        text-[#ffffff]
                        text-[12px]
                        2xs:text-[14px]
                        sticky
                        z-[20]
                        top-0
                        left-0
                    ">{error.message}</div> :
                    totalSize.bytes > 100000000 ?
                    <div className="
                        block
                        w-full
                        py-[15px]
                        px-[3%]
                        bg-[#cd5c5c]
                        font-defaultRegular
                        text-[#ffffff]
                        text-[12px]
                        2xs:text-[14px]
                        sticky
                        z-[20]
                        top-0
                        left-0
                    ">You can only upload upto 100Mb at once.</div> : ""
                }
                <div className="
                    grid
                    grid-cols-2
                    2xs:grid-cols-3
                    xs:grid-cols-4
                    md:grid-cols-5
                    gap-[4px]
                    p-[4px]
                    w-full
                    relative
                    z-[10]
                ">
                    {
                        photos.map(photo => {
                            if (removedPhotos.includes(photo.id)){
                                return ""
                            }
                            else {
                                return (
                                    <div className="
                                        w-full
                                        relative
                                        bg-no-repeat
                                        bg-cover
                                        bg-center
                                        square
                                    " key={photo.id} style={{backgroundImage: `url(${photo.preview_url})`}}>
                                        <button type="button" className="
                                            block
                                            w-[20px]
                                            h-[20px]
                                            bg-[rgba(255,255,255,.2)]
                                            active:bg-[#dd0000]
                                            rounded-[50%]
                                            absolute
                                            top-[6px]
                                            right-[6px]
                                            p-[5px]
                                        " onClick={() => removePhoto(photo.id)}>
                                            <XIcon color="#ffffff"/>
                                        </button>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
            </div>
            {
                photos.length > 0 ?
                <div className="
                    block
                    w-full
                    h-[100px]
                    absolute
                    z-[30]
                    bottom-0
                    left-0
                    bg-[#000000]
                    pt-[5px]
                ">
                    <div className="
                        grid
                        grid-cols-10
                        gap-[10px]
                        w-[94%]
                        mx-auto
                    ">
                        <button type="button" className={`
                            ${totalSize.bytes <= 100000000 ? "col-span-6" : "hidden"}
                            w-full
                            h-[55px]
                            2xs:h-[60px]
                            bg-[#6016a5]
                            active:bg-[#8a2be2]
                            font-defaultRegular
                            text-center
                            text-[#ffffff]
                            text-[12px]
                            2xs:text-[14px]
                            uppercase
                            rounded-[8px]
                        `} onClick={uploadPhotos}>Upload</button>
                        <button type="button" className={`
                            ${totalSize.bytes <= 100000000 ? "col-span-4" : "col-span-10"}
                            w-full
                            h-[55px]
                            2xs:h-[60px]
                            bg-[#111111]
                            active:bg-[#222222]
                            font-defaultRegular
                            text-center
                            text-[#dd0000]
                            text-[12px]
                            2xs:text-[14px]
                            rounded-[8px]
                        `} onClick={removeAll}>Remove All</button>
                    </div>
                </div> : ""
            }
        </div>
    )

}

export default PhotosUploader