import { Link, useLocation } from "react-router-dom"
import { signOut } from "firebase/auth"
import { useStore, useUserStore, useAssetsStore } from "../store"
import { fbAuth } from "../firebaseInit"
import axios from "axios"
import { Toast } from "@capacitor/toast"
import { Haptics } from "@capacitor/haptics"
import { Dialog } from "@capacitor/dialog"
import LeftArrow from "../components/icons/LeftArrow"
import PenIcon from "../components/icons/Pen"
import HistoryIcon from "../components/icons/History"
import ImageIcon from "../components/icons/Image"
import TosIcon from "../components/icons/Tos"
import PrivacyPolicyIcon from "../components/icons/PrivacyPolicy"
import PhoneIcon from "../components/icons/Phone"
import ChevronRight from "../components/icons/ChevronRight"
import DeleteIcon from "../components/icons/Delete"
import NameEditor from "../components/NameEditor"

const Menu = () => {
    
    const location = useLocation()
    const somethingIsLoading = useStore(state => state.somethingIsLoading)
    const locationQueries = useStore(state => state.locationQueries)
    const setSomethingIsLoading = useStore(state => state.setSomethingIsLoading)
    const userData = useUserStore(state => state.userData)
    const appAssets = useAssetsStore(state => state.data)
    
    const signUserOut = async () => {
        if (somethingIsLoading || !userData) return

        setSomethingIsLoading(true)
        
        try {
            await signOut(fbAuth)
            setSomethingIsLoading(false)
        }
        catch (err){
            setSomethingIsLoading(false)
            await Toast.show({
                text: "Something went wrong, please try again.",
                duration: "long",
                position: "bottom"
            })
            await Haptics.notification({
                type: "ERROR"
            })
        }
    }

    const requestAccountDeletetion = async () => {
        const confirmation = await Dialog.confirm({
            title: "Reqesut to delete account",
            message: "Are you sure you want to delete your account? Your name, phone number and uploaded photos will be deleted within a few days."
        })
        if (!confirmation.value || !userData || somethingIsLoading || !appAssets) return

        setSomethingIsLoading(true)

        try {
            await axios.get(`${appAssets.apiBaseUrl}/request-to-delete-account`, {
                headers: {
                    Authorization: `Bearer ${userData.accessToken}`
                }
            })
            
            signUserOut()
            await Toast.show({
                text: "Request sent successfully.",
                duration: "long",
                position: "bottom"
            })
            setSomethingIsLoading(false)
        }
        catch (err){
            setSomethingIsLoading(false)
            await Haptics.notification({
                type: "ERROR"
            })
            setTimeout(async () => {
                await Dialog.alert({
                    text: "Error",
                    message: (err && err.response && err.response.data && err.response.data.message) ? err.response.data.message : "Something went wrong, please try again.",
                    buttonTitle: "Okay"
                })
            }, 200)
        }
    }
    
    return (
        <div className={`
            block
            w-full
            h-full
            overflow-hidden
            absolute
            z-[20]
            ${location.pathname === "/menu" ? "top-0" : "top-[110%]"}
            left-0
            bg-[#000000]
        `}>
            {
                (userData && location.pathname === "/menu" && locationQueries.includes("edit-name")) ?
                <NameEditor/> : ""
            }
            <div className={`
                block
                w-full
                h-full
                overflow-auto
                relative
                z-[10]
                pt-[30px]
                scrollbar-hidden
            `}>
                <div className="
                    block
                    w-[94%]
                    mx-auto
                ">
                    <button type="button" className="
                        block
                        w-[50px]
                        h-[50px]
                        border
                        border-solid
                        border-[#333333]
                        bg-[#222222]
                        active:bg-[#333333]
                        rounded-[50%]
                        mb-[30px]
                        p-[13px]
                    " onClick={() => window.history.back()}>
                        <LeftArrow color="#ffffff"/>
                    </button>
                </div>
                {
                    userData ?
                    <div className="
                        block
                        w-full
                        pb-[30px]
                    ">
                        <div className="
                            block
                            w-[94%]
                            mx-auto
                        ">
                            <h2 className="
                                block
                                w-full
                                font-defaultRegular
                                text-left
                                text-[#ffffff]
                                text-[25px]
                                2xs:text-[30px]
                                relative
                                pr-[50px]
                            ">
                                {userData.displayName || "No Name"}
                                <Link to="?edit-name" className="
                                    block
                                    w-[50px]
                                    h-[50px]
                                    absolute
                                    top-1/2
                                    -translate-y-1/2
                                    right-0
                                    p-[16px]
                                    active:bg-[#222222]
                                "><PenIcon color="#ffffff"/></Link>
                            </h2>
                            <h3 className="
                                block
                                w-full
                                font-defaultRegular
                                text-left
                                text-[#888888]
                                text-[12px]
                                2xs:text-[14px]
                                relative
                            ">{userData.phoneNumber}</h3>
                            <div className="
                                block
                                w-full
                                text-left
                            ">
                                <button type="button" className="
                                    inline-block
                                    px-[30px]
                                    h-[40px]
                                    mt-[30px]
                                    border
                                    border-solid
                                    border-[#333333]
                                    bg-[#222222]
                                    active:bg-[#333333]
                                    rounded-[4px]
                                    text-[#cd5c5c]
                                    font-defaultBold
                                    text-center
                                    text-[12px]
                                    2xs:text-[14px]
                                " onClick={signUserOut}>Sign Out</button>
                            </div>
                        </div>
                    </div> :
                    <div className="
                        block
                        w-full
                        pb-[30px]
                    ">
                        <div className="
                            block
                            w-[94%]
                            mx-auto
                        ">
                            <h2 className="
                                block
                                w-full
                                font-defaultRegular
                                text-left
                                text-[#ffffff]
                                text-[25px]
                                2xs:text-[30px]
                            ">Anonymous User</h2>
                            <h3 className="
                                block
                                w-full
                                font-defaultRegular
                                text-left
                                text-[#888888]
                                text-[12px]
                                2xs:text-[14px]
                                relative
                            ">Sign in to start booking.</h3>
                            <Link to="/signin" className="
                                block
                                w-full
                                leading-[55px]
                                2xs:leading-[60px]
                                mt-[30px]
                                bg-[#8a2be2]
                                active:bg-[#87ceeb]
                                rounded-[10px]
                                text-[#ffffff]
                                font-defaultBold
                                text-center
                                text-[14px]
                                2xs:text-[16px]
                            ">Sign In</Link>
                        </div>
                    </div>
                }
                <div className="
                    block
                    w-full
                    py-[20px]
                    border-y-[10px]
                    border-solid
                    border-[#111111]
                ">
                    <Link to="/history" className="
                        block
                        w-full
                        py-[20px]
                        active:bg-[#222222]
                    ">
                        <div className="
                            block
                            w-[94%]
                            mx-auto
                            relative
                            pl-[40px]
                            2xs:pl-[50px]
                            pr-[30px]
                        ">
                            <div className="
                                block
                                w-[25px]
                                2xs:w-[30px]
                                h-[25px]
                                2xs:h-[30px]
                                absolute
                                top-1/2
                                -translate-y-1/2
                                left-0
                            "><HistoryIcon color="#87ceeb"/></div>
                            <div className="
                                block
                                w-full
                                font-defaultBold
                                text-left
                                text-[#ffffff]
                                text-[13px]
                                2xs:text-[15px]
                            ">Booking History</div>
                            <div className="
                                block
                                w-[15px]
                                2xs:w-[20px]
                                h-[15px]
                                2xs:h-[20px]
                                absolute
                                top-1/2
                                -translate-y-1/2
                                right-0
                            "><ChevronRight color="#888888"/></div>
                        </div>
                    </Link>
                    <Link to="/my-photos" className="
                        block
                        w-full
                        py-[20px]
                        active:bg-[#222222]
                    ">
                        <div className="
                            block
                            w-[94%]
                            mx-auto
                            relative
                            pl-[40px]
                            2xs:pl-[50px]
                            pr-[30px]
                        ">
                            <div className="
                                block
                                w-[25px]
                                2xs:w-[30px]
                                h-[25px]
                                2xs:h-[30px]
                                absolute
                                top-1/2
                                -translate-y-1/2
                                left-0
                            "><ImageIcon color="#87ceeb"/></div>
                            <div className="
                                block
                                w-full
                                font-defaultBold
                                text-left
                                text-[#ffffff]
                                text-[13px]
                                2xs:text-[15px]
                            ">My Photos</div>
                            <div className="
                                block
                                w-[15px]
                                2xs:w-[20px]
                                h-[15px]
                                2xs:h-[20px]
                                absolute
                                top-1/2
                                -translate-y-1/2
                                right-0
                            "><ChevronRight color="#888888"/></div>
                        </div>
                    </Link>
                    <a href="https://tos.false9saikot.in" target="_blank" rel="noopener noreferrer" className="
                        block
                        w-full
                        py-[20px]
                        active:bg-[#222222]
                    ">
                        <div className="
                            block
                            w-[94%]
                            mx-auto
                            relative
                            pl-[40px]
                            2xs:pl-[50px]
                            pr-[30px]
                        ">
                            <div className="
                                block
                                w-[25px]
                                2xs:w-[30px]
                                h-[25px]
                                2xs:h-[30px]
                                absolute
                                top-1/2
                                -translate-y-1/2
                                left-0
                            "><TosIcon color="#87ceeb"/></div>
                            <div className="
                                block
                                w-full
                                font-defaultBold
                                text-left
                                text-[#ffffff]
                                text-[13px]
                                2xs:text-[15px]
                            ">Terms of Service</div>
                            <div className="
                                block
                                w-[15px]
                                2xs:w-[20px]
                                h-[15px]
                                2xs:h-[20px]
                                absolute
                                top-1/2
                                -translate-y-1/2
                                right-0
                            "><ChevronRight color="#888888"/></div>
                        </div>
                    </a>
                    <a href="https://privacy-policy.false9saikot.in" target="_blank" rel="noopener noreferrer" className="
                        block
                        w-full
                        py-[20px]
                        active:bg-[#222222]
                    ">
                        <div className="
                            block
                            w-[94%]
                            mx-auto
                            relative
                            pl-[40px]
                            2xs:pl-[50px]
                            pr-[30px]
                        ">
                            <div className="
                                block
                                w-[25px]
                                2xs:w-[30px]
                                h-[25px]
                                2xs:h-[30px]
                                absolute
                                top-1/2
                                -translate-y-1/2
                                left-0
                            "><PrivacyPolicyIcon color="#87ceeb"/></div>
                            <div className="
                                block
                                w-full
                                font-defaultBold
                                text-left
                                text-[#ffffff]
                                text-[13px]
                                2xs:text-[15px]
                            ">Privacy Policy</div>
                            <div className="
                                block
                                w-[15px]
                                2xs:w-[20px]
                                h-[15px]
                                2xs:h-[20px]
                                absolute
                                top-1/2
                                -translate-y-1/2
                                right-0
                            "><ChevronRight color="#888888"/></div>
                        </div>
                    </a>
                    <a href={`tel:${appAssets.contactPhoneNumber}`} className="
                        block
                        w-full
                        py-[20px]
                        active:bg-[#222222]
                    ">
                        <div className="
                            block
                            w-[94%]
                            mx-auto
                            relative
                            pl-[40px]
                            2xs:pl-[50px]
                            pr-[30px]
                        ">
                            <div className="
                                block
                                w-[25px]
                                2xs:w-[30px]
                                h-[25px]
                                2xs:h-[30px]
                                absolute
                                top-1/2
                                -translate-y-1/2
                                left-0
                            "><PhoneIcon color="#87ceeb"/></div>
                            <div className="
                                block
                                w-full
                                font-defaultBold
                                text-left
                                text-[#ffffff]
                                text-[13px]
                                2xs:text-[15px]
                            ">Contact Us</div>
                            <div className="
                                block
                                w-[15px]
                                2xs:w-[20px]
                                h-[15px]
                                2xs:h-[20px]
                                absolute
                                top-1/2
                                -translate-y-1/2
                                right-0
                            "><ChevronRight color="#888888"/></div>
                        </div>
                    </a>
                    {
                        userData ?
                        <button type="button" className="
                            block
                            w-full
                            py-[20px]
                            active:bg-[#222222]
                        " onClick={requestAccountDeletetion}>
                            <div className="
                                block
                                w-[94%]
                                mx-auto
                                relative
                                pl-[40px]
                                2xs:pl-[50px]
                            ">
                                <div className="
                                    block
                                    w-[25px]
                                    2xs:w-[30px]
                                    h-[25px]
                                    2xs:h-[30px]
                                    absolute
                                    top-1/2
                                    -translate-y-1/2
                                    left-0
                                    p-[3px]
                                "><DeleteIcon color="#cd5c5c"/></div>
                                <div className="
                                    block
                                    w-full
                                    font-defaultRegular
                                    text-left
                                    text-[#cd5c5c]
                                    text-[13px]
                                    2xs:text-[15px]
                                ">Request to Delete my Account</div>
                            </div>
                        </button> : ""
                    }
                </div>
                <div className="
                    block
                    w-full
                    overflow-hidden
                    relative
                    border-t-[30px]
                    border-solid
                    border-[#000000]
                ">
                    <div className="
                        block
                        w-full
                        pb-[100%]
                        sm:pb-[400px]
                        relative
                        z-[1]
                    "></div>
                    <iframe
                        title="google-maps"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3635.224033146175!2d93.71687381548709!3d24.33868748430264!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x374eb3f236247443%3A0x506c60d45b2d673f!2sFALSE%209%20%2C%20Saikot!5e0!3m2!1sen!2sin!4v1675261128653!5m2!1sen!2sin"
                        allowFullScreen={true}
                        referrerPolicy="no-referrer-when-downgrade"
                        className="
                            block
                            w-full
                            h-full
                            absolute
                            z-[10]
                            top-0
                            left-0
                        "
                        style={{
                            filter: "invert(100%)"
                        }}
                    ></iframe>
                </div>
            </div>
        </div>
    )

}

export default Menu