const fn = hour => {
    
    let ampm = "am"
    if (hour < 12){
        ampm = "am"
    }
    else {
        ampm = "pm"
    }

    if (hour > 12){
        hour = hour-12
    }
    if (hour === 0){
        hour = 12
    }
    
    return {
        hour,
        ampm
    }

}

export default fn