import { useState, useEffect, useRef, useCallback } from "react"
import { Link } from "react-router-dom"
import { collection, query, where, orderBy, getDocs, limit, startAfter } from "firebase/firestore"
import axios from "axios"
import { Toast } from "@capacitor/toast"
import { Haptics } from "@capacitor/haptics"
import { useStore, useUserStore, useAssetsStore } from "../store"
import { db } from "../firebaseInit"
import hourConverter from "../lib/24HourTo12Hour"
import countdownTimer from "../lib/countdownTimer"
import LeftArrow from "../components/icons/LeftArrow"
import EmptyIcon from "../components/icons/Empty"
import CheckIcon from "../components/icons/Check"
import XIcon from "../components/icons/XIcon"
import SpinnerLight from "../images/spinner-light.gif"

const History = () => {

    const [ now, setNow ] = useState(new Date())
    const [ init, setInit ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState(null)
    const [ lastItem, setLastItem ] = useState(null)
    const [ list, setList ] = useState([])
    const [ hourToCancel, setHourToCancel ] = useState("")
    const userData = useUserStore(state => state.userData)
    const nameOfMonths = useStore(state => state.nameOfMonths)
    const locationQueries = useStore(state => state.locationQueries)
    const somethingIsLoading = useStore(state => state.somethingIsLoading)
    const setSomethingIsLoading = useStore(state => state.setSomethingIsLoading)
    const appAssets = useAssetsStore(state => state.data)
    const canLoadMore = useRef(true)

    const getHistory = useCallback(async () => {
        if (!userData || loading || !canLoadMore.current) return

        setInit(true)
        setLoading(true)
        setError(null)
        canLoadMore.current = false
        
        try {
            const q = lastItem ? query(
                collection(db, "bookings"),
                where("userId", "==", userData.uid),
                orderBy("bookedAt", "desc"),
                startAfter(lastItem),
                limit(30)
            ) : query(
                collection(db, "bookings"),
                where("userId", "==", userData.uid),
                orderBy("bookedAt", "desc"),
                limit(30)
            )
            const snapshot = await getDocs(q)
            setLoading(false)
            
            if (!snapshot.empty){
                if (snapshot.size >= 30){
                    canLoadMore.current = true
                }
                const newList = []
                snapshot.docs.forEach(d => {
                    newList.push(
                        {
                            id: d.id,
                            ...d.data()
                        }
                    )
                })
                setList([
                    ...list,
                    ...newList
                ])
                setLastItem(snapshot.docs[snapshot.docs.length-1])
            }
        }
        catch (err){
            setLoading(false)
            setError({
                message: "Something went wrong, please try again."
            })
        }
    }, [userData, loading, lastItem, list])

    const retry = () => {
        canLoadMore.current = true
        getHistory()
    }
    
    const onScroll = e => {
        const sh = e.target.scrollHeight
        const ch = e.target.clientHeight
        const st = e.target.scrollTop
        const trigger = ch/3
        const x = sh-(ch+trigger)
        
        if (sh > ch){
            if (x <= st){
                getHistory()
            }
        }
    }

    const cancel = async () => {
        if (somethingIsLoading || !userData || !appAssets || !hourToCancel) return
        
        setSomethingIsLoading(true)
        window.history.back()
        
        try {
            await axios.get(`${appAssets.apiBaseUrl}/cancel-booking?bookingId=${hourToCancel}`, {
                headers: {
                    Authorization: `Bearer ${userData.accessToken}`
                }
            })

            setSomethingIsLoading(false)

            const newList = []
            list.forEach(li => {
                if (li.id === hourToCancel){
                    newList.push({
                        ...li,
                        status: "cancelled"
                    })
                }
                else {
                    newList.push(li)
                }
            })
            setList(newList)
        }
        catch (err){
            setSomethingIsLoading(false)
            await Toast.show({
                text: (err && err.response && err.response.data && err.response.data.message) ? err.response.data.message : "Something went wrong, please try again.",
                duration: "long",
                position: "bottom"
            })
            await Haptics.notification({
                type: "ERROR"
            })
        }
    }
    
    useEffect(() => {
        if (!init){
            getHistory()
        }
    }, [init, getHistory])

    useEffect(() => {
        const fn = () => {
            setNow(new Date())
            
            if (window.location.pathname === "/history"){
                setTimeout(fn, 1000)
            }
        }
        fn()
    }, [])

    useEffect(() => {
        if (window.location.search.startsWith("?cancel=")){
            const cncl = window.location.search.split("&")[0]
            const bookingId = cncl ? cncl.split("=")[1] : ""
            if (bookingId){
                setHourToCancel(bookingId)
            }
            else {
                setHourToCancel("")
                window.history.back()
            }
        }
        else {
            setHourToCancel("")
        }
    }, [locationQueries])
    
    return (
        <div className="
            block
            w-full
            h-full
            overflow-hidden
            bg-[#000000]
            absolute
            z-[20]
            top-0
            left-0
            pt-[50px]
        ">
            {
                hourToCancel ?
                <div className="
                    flex
                    w-full
                    h-full
                    overflow-auto
                    py-[50px]
                    bg-[rgba(0,0,0,.8)]
                    absolute
                    z-[30]
                    top-0
                    left-0
                ">
                    <div className="
                        block
                        w-full
                        h-full
                        absolute
                        z-[10]
                        top-0
                        left-0
                    " onClick={() => window.history.back()}></div>
                    <div className="
                        block
                        w-[94%]
                        max-w-[500px]
                        m-auto
                        bg-[#ffffff]
                        rounded-[10px]
                        p-[15px]
                        relative
                        z-[20]
                    ">
                        <div className="
                            block
                            w-full
                            font-defaultBold
                            text-left
                            text-[#111111]
                            text-[18px]
                            2xs:text-[20px]
                        ">Confirm</div>
                        <div className="
                            block
                            w-full
                            font-defaultRegular
                            text-left
                            text-[#111111]
                            text-[12px]
                            2xs:text-[14px]
                        ">Are you sure you want to cancel?</div>
                        <div className="
                            grid
                            grid-cols-2
                            gap-[10px]
                            mt-[15px]
                        ">
                            <button type="button" className="
                                w-full
                                h-[45px]
                                2xs:h-[50px]
                                rounded-[5px]
                                bg-[#dddddd]
                                font-defaultRegular
                                text-center
                                text-[#111111]
                                text-[12px]
                                2xs:text-[14px]
                            " onClick={() => window.history.back()}>No</button>
                            <button type="button" className="
                                w-full
                                h-[45px]
                                2xs:h-[50px]
                                rounded-[5px]
                                bg-[#cd5c5c]
                                font-defaultRegular
                                text-center
                                text-[#ffffff]
                                text-[12px]
                                2xs:text-[14px]
                            " onClick={cancel}>Yes, Continue</button>
                        </div>
                    </div>
                </div> : ""
            }
            <div className={`
                block
                w-full
                h-[50px]
                overflow-hidden
                bg-[#181818]
                absolute
                z-[20]
                top-0
                left-0
            `}>
                <div className="
                    block
                    w-[94%]
                    h-full
                    mx-auto
                    relative
                ">
                    <button type="button" className="
                        block
                        w-[50px]
                        h-[50px]
                        active:bg-[rgba(255,255,255,.1)]
                        p-[15px]
                        absolute
                        top-1/2
                        -translate-y-1/2
                        -left-[10px]
                    " onClick={() => window.history.back()}>
                        <LeftArrow color="#ffffff"/>
                    </button>
                    <h2 className="
                        block
                        w-[94%]
                        mx-auto
                        font-defaultRegular
                        text-center
                        text-[#ffffff]
                        text-[18px]
                        2xs:text-[20px]
                        leading-[50px]
                    ">History</h2>
                </div>
            </div>
            <div className="
                block
                w-[96%]
                mx-auto
                h-full
                overflow-auto
                relative
                z-[10]
                scrollbar-hidden
            " onScroll={onScroll}>
                <div className="
                    grid
                    grid-cols-1
                    3xs:grid-cols-2
                    sm:grid-cols-3
                    gap-[10px]
                    py-[10px]
                ">
                    {
                        list.map(item => {
                            const time = item.hourId.split("-")
                            const hour = Number(time[0])
                            const dateOfMonth = Number(time[1])
                            const month = Number(time[2])-1
                            const year = Number(time[3])
                            const twelveHourFormat = hourConverter(hour)
                            const utcNowMillis = Date.UTC(now.getFullYear(),now.getMonth(),now.getDate(),now.getHours(),now.getMinutes(),now.getSeconds(),now.getMilliseconds())

                            let status = ""
                            let countdown = null
                            if (item.status === "cancelled"){
                                status = "cancelled"
                            }
                            else {
                                const millis = item.utcMillis-utcNowMillis
                                if (utcNowMillis < item.utcMillis){
                                    const allowedMillis = 172800000 // allow cancellation before two days
                                    if (millis >= allowedMillis){
                                        status = "can_be_cancelled"
                                    }
                                    else {
                                        status = "upcoming"
                                        countdown = {
                                            type: "t-",
                                            ...countdownTimer(millis)
                                        }
                                    }
                                }
                                else {
                                    if (Math.abs(millis) <= 3600000){
                                        countdown = {
                                            type: "t+",
                                            ...countdownTimer(Math.abs(millis))
                                        }
                                    }
                                    status = "completed"
                                }
                            }
                            
                            return (
                                <div key={item.id} className="
                                    w-full
                                    bg-[#111111]
                                    rounded-[15px]
                                    border
                                    border-solid
                                    border-[#222222]
                                ">
                                    <div className="
                                        block
                                        w-full
                                        p-[10px]
                                        relative
                                    ">
                                        <div className="
                                            block
                                            w-full
                                            font-defaultBlack
                                            text-left
                                            text-[#87ceeb]
                                            text-[20px]
                                            2xs:text-[23px]
                                            capitalize
                                            overflow-hidden
                                            whitespace-nowrap
                                            text-ellipsis
                                            relative
                                        ">
                                            {twelveHourFormat.hour < 10 ? "0" : ""}
                                            {twelveHourFormat.hour}
                                            <span className="
                                                text-[60%]
                                                font-defaultRegular
                                                ml-[3px]
                                                uppercase
                                            ">{twelveHourFormat.ampm}</span>
                                            <span className={`
                                                text-[10px]
                                                2xs:text-[12px]
                                                font-defaultRegular
                                                ml-[3px]
                                                absolute
                                                top-1/2
                                                -translate-y-1/2
                                                right-0
                                                text-[#ffffff]
                                            `}>₹{item.price}</span>
                                        </div>
                                        <div className="
                                            block
                                            w-full
                                            font-defaultRegular
                                            text-left
                                            text-[#888888]
                                            text-[10px]
                                            2xs:text-[12px]
                                            capitalize
                                            overflow-hidden
                                            whitespace-nowrap
                                            text-ellipsis
                                            mb-[10px]
                                        ">{nameOfMonths[month]} {dateOfMonth}, {year}</div>
                                        {
                                            status === "can_be_cancelled" ?
                                            <Link to={`?cancel=${item.id}`} className="
                                                block
                                                w-full
                                                leading-[40px]
                                                bg-[#990000]
                                                active:bg-[#cc0000]
                                                font-defaultRegular
                                                text-center
                                                text-[#ffffff]
                                                text-[12px]
                                                rounded-[10px]
                                                uppercase
                                            ">Cancel</Link> :
                                            <div className={`
                                                block
                                                w-full
                                                leading-[40px]
                                                bg-[#191919]
                                                font-defaultRegular
                                                text-center
                                                ${status === "cancelled" ? "text-[#cd5c5c]" : "text-[#ffffff]"}
                                                text-[10px]
                                                2xs:text-[12px]
                                                rounded-[10px]
                                                uppercase
                                                relative
                                            `}>
                                                <div className="
                                                    block
                                                    w-[12px]
                                                    h-[12px]
                                                    absolute
                                                    top-1/2
                                                    -translate-y-1/2
                                                    left-[10px]
                                                ">
                                                    {
                                                        (status === "completed" && !countdown) ?
                                                        <CheckIcon color="#87ceeb" strokeWidth={40}/> :
                                                        status === "cancelled" ?
                                                        <XIcon color="#cd5c5c"/> : ""
                                                    }
                                                </div>
                                                {
                                                    countdown ?
                                                    <div className="text-[#ffa500]">
                                                        <span className="inline-block mr-[5px] text-[#ffffff]">{countdown.type}</span>
                                                        <span className="inline-block text-center w-[20px] 2xs:w-[23px]">{countdown.hours}</span>:
                                                        <span className="inline-block text-center w-[20px] 2xs:w-[23px]">{countdown.minutes}</span>:
                                                        <span className="inline-block text-center w-[20px] 2xs:w-[23px]">{countdown.seconds}</span>
                                                    </div> : status
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    loading ?
                    <div className="
                        block
                        w-full
                        py-[20px]
                    ">
                        <img src={SpinnerLight} alt="" className="
                            block
                            w-[40px]
                            mx-auto
                        "/>
                    </div> : ""
                }
                {
                    error ?
                    <div className="
                        block
                        w-full
                        py-[10px]
                        px-[3%]
                        bg-[#dd0000]
                    ">
                        <div className="
                            block
                            w-full
                            font-defaultRegular
                            text-[#ffffff]
                            text-[12px]
                            2xs:text-[14px]
                            text-left
                            pr-[100px]
                            relative
                        ">
                            {error.message}
                            <button type="button" className="
                                block
                                w-[80px]
                                h-[30px]
                                absolute
                                top-1/2
                                -translate-y-1/2
                                right-0
                                bg-[#ffffff]
                                rounded-[4px]
                                font-defaultRegular
                                text-center
                                text-[#111111]
                                text-[10px]
                                2xs:text-[12px]
                                active:opacity-[.8]
                            " onClick={retry}>Retry</button>
                        </div>
                    </div> : ""
                }
                {
                    (init && !loading && !error && list.length === 0) ?
                    <div className="
                        block
                        w-[94%]
                        max-w-[1000px]
                        mx-auto
                        py-[50px]
                    ">
                        <div className="
                            block
                            w-[50px]
                            h-[50px]
                            mx-auto
                            mb-[10px]
                        ">
                            <EmptyIcon color="#888888"/>
                        </div>
                        <div className="
                            block
                            w-full
                            font-defaultRegular
                            text-[#ffffff]
                            text-[12px]
                            2xs:text-[14px]
                            text-center
                        ">You haven't booked anything yet.</div>
                    </div> : ""
                }
            </div>
        </div>
    )

}

export default History