import { useStore } from "../store"

const Offline = () => {
    
    const networkConnected = useStore(state => state.networkConnected)
    
    return (
        <div className={`
            block
            w-full
            h-[30px]
            overflow-hidden
            bg-[#ff0000]
            absolute
            z-[20]
            ${networkConnected ? "-top-[31px]" : "top-0"}
            left-0
            duration-[.2s]
            ease-in-out
        `}>
            <div className="
                block
                w-full
                leading-[30px]
                font-defaultRegular
                text-center
                text-[12px]
                text-[#ffffff]
            ">You are offline!</div>
        </div>
    )

}

export default Offline