import { useEffect, useRef } from "react"
import { db } from "../../firebaseInit"
import { doc, onSnapshot } from "firebase/firestore"
import { useStore, useAssetsStore } from "../../store"

const AssetsWatcher = () => {

    const unsubscribe = useRef()
    const setSelectedHours = useStore(state => state.setSelectedHours)
    const appAssets = useAssetsStore(state => state.data)
    const setAppAssets = useAssetsStore(state => state.setData)
    
    useEffect(() => {
        if (!unsubscribe.current){
            try {
                unsubscribe.current = onSnapshot(doc(db, "app-assets", "assets"), snapshot => {
                    if (snapshot.exists()){
                        setAppAssets(snapshot.data())
                    }
                })
            }
            catch {}
        }
    }, [setAppAssets])

    useEffect(() => {
        if (!appAssets || !appAssets.available){
            setSelectedHours([])
        }
    }, [appAssets, setSelectedHours])
    
    return (
        <div className="hidden"></div>
    )

}

export default AssetsWatcher