import { useEffect } from "react"
import { useStore, useUserStore } from "../../store"
import { fbAuth } from "../../firebaseInit"

const AuthHandler = () => {
    
    const resetMyPhotos = useStore(state => state.resetMyPhotos)
    const setUserData = useUserStore(state => state.setUserData)
    
    useEffect(() => {
        fbAuth.onAuthStateChanged(auth => {
            if (auth){
                setUserData(auth)
            }
            else {
                setUserData(null)
                resetMyPhotos()
            }
        })
    }, [setUserData, resetMyPhotos])
    
    return (
        <div className="hidden"></div>
    )

}

export default AuthHandler