import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { useStore } from "../store"

const LocationQueryWatcher = () => {

    const location = useLocation()
	const setLocationQueries = useStore(state => state.setLocationQueries)

    // listen to location query change
	useEffect(() => {
		const query = location.search.substring(1)
		if (query){
			const queries = query.split("&")
            const keys = []
            queries.forEach(q => {
                keys.push(q.split("=")[0])
            })
			setLocationQueries(keys)
		}
		else {
			setLocationQueries([])
		}
	}, [location.search, setLocationQueries])
    
    return (
        <div className="hidden"></div>
    )

}

export default LocationQueryWatcher