import { useEffect, useRef } from "react"
import { onSnapshot, collection, query, where, limit } from "firebase/firestore"
import { useStore, useAssetsStore } from "../store"
import { db } from "../firebaseInit"

const SelectedDateWatcher = () => {

    const selectedDate = useStore(state => state.selectedDate)
    const appAssets = useAssetsStore(state => state.data)
    const setHoursForService = useStore(state => state.setHoursForService)
    const selectedHours = useStore(state => state.selectedHours)
    const selectedHoursRef = useRef(selectedHours)
    useEffect(() => {
        selectedHoursRef.current = selectedHours
    }, [selectedHours])
    const setSelectedHours = useStore(state => state.setSelectedHours)
    const somethingIsLoading = useStore(state => state.somethingIsLoading)
    const somethingIsLoadingRef = useRef(somethingIsLoading)
    useEffect(() => {
        somethingIsLoadingRef.current = somethingIsLoading
    }, [somethingIsLoading])
    const firestoreUnsubscribe = useRef(null)

    useEffect(() => {
        if (selectedDate && appAssets){
            setHoursForService({
                loading: true,
                data: null
            })
            
            try {
                if (firestoreUnsubscribe.current){
                    firestoreUnsubscribe.current()
                }
                
                const dateId = `${selectedDate.getDate()}-${selectedDate.getMonth()+1}-${selectedDate.getFullYear()}`
                const q = query(collection(db, "bookings"), where("status", "!=", "cancelled"), where("dateId", "==", dateId), limit(24))
                firestoreUnsubscribe.current = onSnapshot(q, snapshot => {
                    const hours = []
                    appAssets.pricing.hours.forEach(hour => {
                        let ua = false
                        snapshot.forEach(doc => {
                            if (!ua && doc.data().hour === hour.hour){
                                ua = true
                            }
                        })
                        const now = new Date()
                        const todayId = `${now.getDate()}-${now.getMonth()+1}-${now.getFullYear()}`
                        if (todayId === dateId && now.getHours() >= hour.hour){
                            ua = true
                        }
                        hours.push({
                            id: `${hour.hour}-${dateId}`,
                            hour: hour.hour,
                            price: hour.price,
                            ua,
                            dateId
                        })
                    })
                    setHoursForService({
                        loading: false,
                        data: hours
                    })

                    if (selectedHoursRef.current && selectedHoursRef.current.length > 0){
                        const newList = []
                        let someSelectedHoursUnavailable = false
                        selectedHoursRef.current.forEach(sh => {
                            let shouldRemove = false
                            const hour = Number(sh.split("-")[0])
                            const x = appAssets.pricing.hours.filter(h => h.hour === hour)
                            if (!x[0]){
                                shouldRemove = true
                            }
                            snapshot.forEach(doc => {
                                if (!shouldRemove && doc.data().hourId === sh){
                                    shouldRemove = true
                                }
                            })
                            if (!shouldRemove){
                                newList.push(sh)
                            }
                            else {
                                if (!someSelectedHoursUnavailable){
                                    someSelectedHoursUnavailable = true
                                }
                            }
                        })
                        setSelectedHours(newList)
                    }
                })
            }
            catch {
                setHoursForService({
                    loading: false,
                    data: null
                })
            }
        }
    }, [selectedDate, appAssets, setHoursForService, setSelectedHours])

    useEffect(() => {
        if (selectedDate){
            setSelectedHours([])
        }
    }, [selectedDate, setSelectedHours])
    
    return (
        <div className="hidden"></div>
    )

}

export default SelectedDateWatcher