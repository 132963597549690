const Icon = ({color}) => {
    
    return (
        <svg viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-full">
            <path d="M268 131V262L373 330.5" stroke={color || "#111111"} strokeWidth="40"/>
            <path d="M90.0694 235.5C93.9144 199.655 108.035 165.522 130.937 137.315C158.066 103.902 195.865 80.8383 237.988 71.9978C280.111 63.1574 323.992 69.0779 362.266 88.7655C400.54 108.453 430.875 140.709 448.179 180.118C465.483 219.527 468.702 263.689 457.296 305.19C445.889 346.692 420.552 383.006 385.538 408.035C350.523 433.065 307.964 445.286 265.004 442.647C228.74 440.42 194.054 427.717 165.062 406.291L165.103 406.246C194.084 427.663 228.757 440.36 265.008 442.587C307.954 445.225 350.5 433.008 385.503 407.986C420.506 382.965 445.835 346.663 457.238 305.174C468.64 263.686 465.422 219.538 448.124 180.142C430.826 140.746 400.5 108.5 362.239 88.8192C323.977 69.1379 280.109 63.2193 238 72.0569C195.891 80.8946 158.104 103.95 130.984 137.354C108.091 165.55 93.9749 199.669 90.1301 235.5H90.0694Z" stroke={color || "#111111"} strokeWidth="40"/>
            <path d="M173 240.5H18.5L95 335.5L173 240.5Z" fill={color || "#111111"}/>
        </svg>
    )

}

export default Icon