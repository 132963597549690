const datesOfMonth = dayOneOfMonth => {

    if (typeof(dayOneOfMonth) === "number"){
        dayOneOfMonth = new Date(dayOneOfMonth)
    }
    
    const datesOfMonth = []
    for (let i = 1; i < 32; i++){
        const date = new Date(dayOneOfMonth.setDate(i))
        if (dayOneOfMonth.getMonth() === date.getMonth()){
            datesOfMonth.push(date)
        }
    }

    return datesOfMonth

}

export default datesOfMonth