const Icon = ({color}) => {

    return (
        <svg viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M405.559 184.485V51C405.559 39.9543 396.604 31 385.559 31H82C70.9543 31 62 39.9543 62 51V421.085C62 432.13 70.9543 441.084 82 441.084H191.25" stroke={color || "#111111"} strokeWidth="40"/>
            <path d="M429.275 344.622H279.677C268.631 344.622 259.677 353.576 259.677 364.622V461C259.677 472.046 268.631 481 279.677 481H429.275C440.321 481 449.275 472.046 449.275 461V364.622C449.275 353.576 440.321 344.622 429.275 344.622Z" stroke={color || "#111111"} strokeWidth="40"/>
            <mask id="path-3-inside-1_133_13" fill="white">
                <path d="M430.194 353.113C434.33 341.064 435.541 328.205 433.725 315.596C431.91 302.987 427.121 290.99 419.753 280.598C412.386 270.205 402.652 261.714 391.356 255.826C380.059 249.938 367.524 246.822 354.785 246.736C342.046 246.649 329.47 249.594 318.094 255.328C306.718 261.061 296.87 269.419 289.362 279.71C281.854 290.002 276.902 301.932 274.915 314.515C272.928 327.098 273.963 339.973 277.935 352.077L311.405 341.095C309.176 334.3 308.595 327.073 309.71 320.009C310.826 312.945 313.605 306.249 317.82 300.472C322.035 294.694 327.563 290.003 333.949 286.784C340.335 283.566 347.395 281.912 354.545 281.961C361.696 282.01 368.733 283.759 375.074 287.064C381.416 290.369 386.88 295.136 391.015 300.97C395.151 306.804 397.839 313.538 398.858 320.616C399.878 327.694 399.198 334.913 396.876 341.676L430.194 353.113Z"/>
            </mask>
            <path d="M430.194 353.113C434.33 341.064 435.541 328.205 433.725 315.596C431.91 302.987 427.121 290.99 419.753 280.598C412.386 270.205 402.652 261.714 391.356 255.826C380.059 249.938 367.524 246.822 354.785 246.736C342.046 246.649 329.47 249.594 318.094 255.328C306.718 261.061 296.87 269.419 289.362 279.71C281.854 290.002 276.902 301.932 274.915 314.515C272.928 327.098 273.963 339.973 277.935 352.077L311.405 341.095C309.176 334.3 308.595 327.073 309.71 320.009C310.826 312.945 313.605 306.249 317.82 300.472C322.035 294.694 327.563 290.003 333.949 286.784C340.335 283.566 347.395 281.912 354.545 281.961C361.696 282.01 368.733 283.759 375.074 287.064C381.416 290.369 386.88 295.136 391.015 300.97C395.151 306.804 397.839 313.538 398.858 320.616C399.878 327.694 399.198 334.913 396.876 341.676L430.194 353.113Z" stroke={color || "#111111"} strokeWidth="80" mask="url(#path-3-inside-1_133_13)"/>
            <circle cx="355.664" cy="413.049" r="26.6103" fill={color || "#111111"}/>
            <path d="M170.342 134.59H342.834" stroke={color || "#111111"} strokeWidth="40"/>
            <path d="M172.243 219.648H276.784" stroke={color || "#111111"} strokeWidth="40"/>
            <circle cx="128.526" cy="134.59" r="19.0074" fill={color || "#111111"}/>
            <circle cx="128.526" cy="220.124" r="19.0074" fill={color || "#111111"}/>
        </svg>
    )

}

export default Icon