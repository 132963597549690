import React from "react"
import ReactDOM from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { defineCustomElements } from "@ionic/pwa-elements/loader"
import "./styles/index.css"
import App from "./App"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
    <BrowserRouter>
        <App/>
    </BrowserRouter>
)

defineCustomElements(window)
serviceWorkerRegistration.register()