import dayjs from "dayjs"
import isToday from "dayjs/plugin/isToday"
import isTomorrow from "dayjs/plugin/isTomorrow"
import LocalizedFormat from "dayjs/plugin/localizedFormat"

dayjs.extend(isToday)
dayjs.extend(isTomorrow)
dayjs.extend(LocalizedFormat)

const dateDisplay = date => {
    if (dayjs(date).isToday()){
        return "Today"
    }
    if (dayjs(date).isTomorrow()){
        return "Tomorrow"
    }

    return `${dayjs(date).format("dddd")} - ${dayjs(date).format("LL")}`
}

export default dateDisplay