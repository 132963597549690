const Icon = ({color}) => {

    return (
        <svg viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M469.333 360.96V424.96C469.357 430.901 468.14 436.782 465.76 442.226C463.38 447.67 459.889 452.557 455.511 456.573C451.133 460.59 445.964 463.648 440.336 465.551C434.708 467.455 428.744 468.161 422.827 467.627C357.18 460.494 294.123 438.062 238.72 402.133C187.175 369.38 143.474 325.678 110.72 274.133C74.6662 218.479 52.2291 155.115 45.2266 89.1733C44.6935 83.274 45.3946 77.3282 47.2853 71.7147C49.176 66.1011 52.2148 60.9427 56.2083 56.5679C60.2018 52.1931 65.0625 48.6978 70.4808 46.3045C75.8992 43.9112 81.7566 42.6723 87.68 42.6667H151.68C162.033 42.5648 172.07 46.231 179.92 52.982C187.77 59.7331 192.898 69.1082 194.347 79.36C197.048 99.8414 202.058 119.952 209.28 139.307C212.15 146.942 212.771 155.241 211.07 163.219C209.369 171.197 205.416 178.52 199.68 184.32L172.587 211.413C202.956 264.822 247.178 309.044 300.587 339.413L327.68 312.32C333.48 306.584 340.803 302.631 348.781 300.93C356.759 299.229 365.058 299.85 372.693 302.72C392.048 309.942 412.159 314.952 432.64 317.653C443.003 319.115 452.467 324.335 459.233 332.32C465.998 340.305 469.593 350.498 469.333 360.96Z" stroke={color || "#111111"} strokeWidth="40" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )

}

export default Icon