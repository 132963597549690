import { useStore } from "../store"
import hourConverter from "../lib/24HourTo12Hour"
import CheckIcon from "./icons/Check"

const HourSelector = () => {

    const hoursForService = useStore(state => state.hoursForService)
    const selectedHours = useStore(state => state.selectedHours)
    const setSelectedHours = useStore(state => state.setSelectedHours)

    const selectHour = id => {
        if (selectedHours.includes(id)){
            setSelectedHours(Array.from(new Set(selectedHours.filter(i => i !== id))))
        }
        else {
            setSelectedHours(Array.from(new Set([...selectedHours,id])))
        }
    }
    
    return (
        <div className="
            grid
            w-full
            grid-cols-2
            3xs:grid-cols-3
            sm:grid-cols-4
            md:grid-cols-5
            gap-[10px]
        ">
            {
                hoursForService.data.map((hour, i) => {
                    const twelveHourFormat = hourConverter(hour.hour)

                    return (
                        <div key={i} className={`
                            w-full
                            min-h-[60px]
                            ${hour.ua ? "pr-[10px] bg-[#111111]" : "bg-[#191919] active:bg-[#222222] pr-[30px]"}
                            rounded-[5px]
                            py-[10px]
                            pl-[10px]
                            relative
                        `} onClick={hour.ua ? null : () => selectHour(hour.id)}>
                            {
                                !hour.ua ?
                                <div className={`
                                    block
                                    w-[20px]
                                    h-[20px]
                                    border
                                    border-solid
                                    ${selectedHours.includes(hour.id) ? "bg-[#8a2be2] border-[#8a2be2]" : "bg-[rgba(255,255,255,.1)] border-[rgba(255,255,255,.2)]"}
                                    absolute
                                    top-1/2
                                    -translate-y-1/2
                                    right-[10px]
                                    rounded-[4px]
                                    p-[3px]
                                `}>
                                    {
                                        selectedHours.includes(hour.id) ?
                                        <CheckIcon color="#ffffff" strokeWidth="80" animate={true}/> : ""
                                    }
                                </div> : ""
                            }
                            <div className={`
                                block
                                w-full
                                font-defaultBlack
                                text-left
                                ${hour.ua ? "text-[#cd5c5c]" : "text-[#ffffff]"}
                                text-[16px]
                                2xs:text-[18px]
                                leading-[20px]
                                uppercase
                                overflow-hidden
                                whitespace-nowrap
                                text-ellipsis
                            `}>{twelveHourFormat.hour < 10 ? "0" : ""}{twelveHourFormat.hour} <span className="text-[60%] font-defaultRegular">{twelveHourFormat.ampm}</span></div>
                            <div className={`
                                block
                                w-full
                                font-defaultBold
                                text-left
                                ${hour.ua ? "text-[#444444]" : "text-[#dddddd]"}
                                text-[10px]
                                2xs:text-[11px]
                                capitalize
                                overflow-hidden
                                whitespace-nowrap
                                text-ellipsis
                            `}>{hour.ua ? "U/A" : `₹${hour.price}`}</div>
                        </div>
                    )
                })
            }
        </div>
    )

}

export default HourSelector