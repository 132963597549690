import { useState, useEffect, useRef } from "react"
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth"
import { fbAuth } from "../firebaseInit"
import { phone } from "phone"
import { useUserStore } from "../store"
import LeftArrow from "../components/icons/LeftArrow"
import SpinnerLight from "../images/spinner-light.gif"
import Icon from "../images/icon.png"

const Signin = () => {
    
    const userData = useUserStore(state => state.userData)
    const [ phoneNumber, setPhoneNumber ] = useState("")
    const [ loading, setLoading ] = useState(false)
    const [ error, setError ] = useState(null)
    const [ otp, setOtp ] = useState("")
    const [ confirmationResult, setConfirmationResult ] = useState(null)
    const phoneNumberInputRef = useRef(null)
    const otpInputRef = useRef(null)
    const recaptchaVerifier = useRef(null)
    
    const onPhoneNumberChange = e => {
        const pn = e.target.value
        
        if (pn.length <= 10){
            setPhoneNumber(pn)
        }
    }

    const onOtpChange = e => {
        const code = e.target.value

        if (code.length <= 6){
            setOtp(code)
        }
    }

    const submitPhoneNumber = async () => {
        if (loading || userData) return
        
        // validate phone number
        if (!phoneNumber){
            return setError({
                message: "Please enter your phone number"
            })
        }
        
        const inputPhoneNumber = phoneNumber === "1111111111" ? {
            phoneNumber: `+91${phoneNumber}`,
            isValid: true
        } : phone(phoneNumber, {country: "IN"})
        
        if (!inputPhoneNumber.isValid){
            return setError({
                message: "Please enter a valid phone number."
            })
        }
        
        setLoading(true)
        setError(null)
        setConfirmationResult(null)
        
        try {
            // init recaptcha
            recaptchaVerifier.current = new RecaptchaVerifier(fbAuth, "phone-number-submit-btn", {
                size: "invisible"
            })

            // send verification code to phone number
            const cr = await signInWithPhoneNumber(fbAuth, inputPhoneNumber.phoneNumber, recaptchaVerifier.current)
            
            setLoading(false)
            setConfirmationResult(cr)
        }
        catch (err){
            setLoading(false)

            let errMessage = (err && err.message) ? err.message : "Something went wrong, please try again."
            if (err){
                if (err.code === "auth/invalid-phone-number"){
                    errMessage = "Please enter a valid phone number."
                }
            }
            setError({
                message: errMessage
            })
        }
    }

    const submitOtp = async e => {
        e.preventDefault()

        if (loading || userData || !confirmationResult) return

        // validate otp
        if (otp.length !== 6){
            return setError({
                message: "Please enter a 6 digit verification code."
            })
        }

        setLoading(true)
        setError(null)
        
        try {
            await confirmationResult.confirm(otp)
            setLoading(false)
        }
        catch (err){
            setLoading(false)
            
            let errMessage = (err && err.message) ? err.message : "Something went wrong, please try again."
            if (err){
                if (err.code === "auth/invalid-verification-code"){
                    errMessage = "Invalid verification code."
                }
            }
            setError({
                message: errMessage
            })
        }
    }
    
    useEffect(() => {
        if (phoneNumberInputRef.current){
            phoneNumberInputRef.current.focus()
        }
    }, [])
    
    useEffect(() => {
        if (confirmationResult && otpInputRef.current){
            otpInputRef.current.focus()
        }
    }, [confirmationResult])
    
    useEffect(() => {
        if (userData){
            if (window.location.pathname === "/signin"){
                window.history.back()
            }
        }
    }, [userData])
    
    return (
        <div className="
            block
            w-full
            h-full
            overflow-auto
            bg-[#000000]
            absolute
            z-[20]
            top-0
            left-0
        ">
            <div className="
                block
                w-[94%]
                max-w-[1000px]
                mx-auto
                pt-[20px]
                pb-[50px]
                relative
            ">
                <button className="
                    block
                    w-[45px]
                    h-[45px]
                    rounded-[50%]
                    border
                    border-solid
                    border-[#444444]
                    p-[12px]
                    bg-[#222222]
                    active:bg-[#333333]
                    mb-[50px]
                " onClick={() => window.history.back()}>
                    <LeftArrow color="#ffffff"/>
                </button>
                <img src={Icon} alt="" className="
                    block
                    w-[80px]
                    2xs:w-[100px]
                    h-[80px]
                    2xs:h-[100px]
                    mb-[30px]
                "/>
                {
                    !confirmationResult ?
                    <>
                        <h1 className="
                            block
                            w-full
                            font-defaultBold
                            text-left
                            text-[35px]
                            2xs:text-[40px]
                            xs:text-[45px]
                            text-[#ffffff]
                        ">Sign In.</h1>
                        <p className="
                            block
                            w-full
                            font-defaultRegular
                            text-left
                            text-[12px]
                            2xs:text-[14px]
                            text-[#888888]
                        ">Please enter your phone number.</p>
                        {
                            error ?
                            <div className="
                                block
                                w-full
                                p-[10px]
                                bg-[#bb0000]
                                mt-[20px]
                                rounded-[6px]
                                font-defaultRegular
                                text-left
                                text-[#ffffff]
                                text-[13px]
                                2xs:text-[14px]
                                leading-[20px]
                            ">{error.message}</div> : ""
                        }
                        <div className="
                            block
                            w-full
                            h-[55px]
                            2xs:h-[60px]
                            overflow-hidden
                            relative
                            bg-[#333333]
                            mt-[20px]
                            rounded-[10px]
                        ">
                            <div className="
                                block
                                w-[50px]
                                absolute
                                z-[20]
                                top-1/2
                                -translate-y-1/2
                                left-0
                                font-defaultBold
                                text-[14px]
                                2xs:text-[16px]
                                text-center
                                text-[#ffffff]
                            ">+91</div>
                            <input type="text" pattern="[0-9]*" inputMode="numeric" id="phone-number-input" name="phone" placeholder="000-000-0000" className="
                                block
                                w-full
                                h-[55px]
                                2xs:h-[60px]
                                font-defaultBold
                                text-[14px]
                                2xs:text-[16px]
                                text-left
                                text-[#ffffff]
                                pr-[10px]
                                pl-[50px]
                                relative
                                z-[10]
                            " value={phoneNumber} onChange={onPhoneNumberChange} ref={phoneNumberInputRef}/>
                        </div>
                        <button type="button" onClick={submitPhoneNumber} id="phone-number-submit-btn" className={`
                            block
                            w-full
                            h-[55px]
                            2xs:h-[60px]
                            font-defaultBold
                            text-[14px]
                            2xs:text-[16px]
                            ${!loading ? "bg-[#8a2be2] active:bg-[#87ceeb]" : "bg-[#444444]"}
                            text-[#ffffff]
                            text-center
                            mt-[10px]
                            rounded-[10px]
                        `}>
                            {
                                loading ?
                                <img src={SpinnerLight} alt="" className="
                                    block
                                    w-[20px]
                                    h-[20px]
                                    mx-auto
                                "/> :
                                "Continue"
                            }
                        </button>
                        <p className="
                            block
                            w-full
                            font-defaultRegular
                            text-left
                            text-[11px]
                            2xs:text-[12px]
                            leading-[15px]
                            2xs:leading-[16px]
                            text-[#888888]
                            mt-[15px]
                        ">By continuing you agree to our <a href="https://privacy-policy.false9saikot.in" target="_blank" rel="noopener noreferrer" className="text-[#87ceeb] underline">privacy policy</a>.</p>
                    </> :
                    <>
                        <h1 className="
                            block
                            w-full
                            font-defaultBold
                            text-left
                            text-[26px]
                            2xs:text-[30px]
                            xs:text-[35px]
                            leading-[36px]
                            2xs:leading-[40px]
                            xs:leading-[45px]
                            text-[#ffffff]
                        ">Verify Your <br/>Phone Number.</h1>
                        <p className="
                            block
                            w-full
                            font-defaultRegular
                            text-left
                            text-[12px]
                            2xs:text-[14px]
                            leading-[15px]
                            2xs:leading-[16px]
                            text-[#888888]
                            mt-[5px]
                        ">A six digit verification code is sent to your phone number.</p>
                        {
                            error ?
                            <div className="
                                block
                                w-full
                                p-[10px]
                                bg-[#bb0000]
                                mt-[20px]
                                rounded-[6px]
                                font-defaultRegular
                                text-left
                                text-[#ffffff]
                                text-[13px]
                                2xs:text-[14px]
                                leading-[20px]
                            ">{error.message}</div> : ""
                        }
                        <form onSubmit={submitOtp} autoComplete="off" className="
                            block
                            w-full
                            h-[55px]
                            2xs:h-[60px]
                            overflow-hidden
                            relative
                            rounded-[10px]
                            bg-[#333333]
                            pr-[110px]
                            pl-[10px]
                            mt-[30px]
                        ">
                            <input type="text" pattern="[0-9]*" inputMode="numeric" id="otp-input" name="otp" placeholder="00-00-00" autoComplete="off" className="
                                block
                                w-full
                                h-[55px]
                                2xs:h-[60px]
                                font-defaultBold
                                text-[14px]
                                2xs:text-[16px]
                                text-left
                                text-[#ffffff]
                            " value={otp} onChange={onOtpChange} ref={otpInputRef}/>
                            <button type="submit" id="phone-number-submit-btn" className={`
                                block
                                w-[100px]
                                h-[55px]
                                2xs:h-[60px]
                                absolute
                                top-0
                                right-0
                                bg-[#3aacda]
                                ${!loading ? "active:bg-[#8a2be2]" : ""}
                                font-defaultBold
                                text-center
                                text-[14px]
                                text-[#ffffff]
                            `}>
                                {
                                    !loading ?
                                    "Verify" :
                                    <img src={SpinnerLight} alt="" className="
                                        block
                                        w-[20px]
                                        h-[20px]
                                        absolute
                                        top-1/2
                                        -translate-y-1/2
                                        left-1/2
                                        -translate-x-1/2
                                    "/>
                                }
                            </button>
                        </form>
                    </>
                }
            </div>
        </div>
    )

}

export default Signin