const countdownTimer = (milliseconds) => {
    
    // Calculate the days, hours, minutes, and seconds
    let seconds = Math.floor(milliseconds/1000)
    let minutes = Math.floor(seconds/60)
    let hours = Math.floor(minutes/60)

    // Format the time values as two-digit numbers
    hours %= 48
    minutes %= 60
    seconds %= 60
    if (hours < 10) {
      hours = "0" + hours
    }
    if (minutes < 10) {
      minutes = "0" + minutes
    }
    if (seconds < 10) {
      seconds = "0" + seconds
    }
    
    return {
        hours,
        minutes,
        seconds
    }

}

export default countdownTimer