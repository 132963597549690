import { Link, useNavigate } from "react-router-dom"
import { useStore, useAssetsStore } from "../store"
import Pricing from "./Pricing"
import LongRightArrow from "./icons/LongRightArrow"
import HistoryIcon from "./icons/History"
import HoursAvailable from "./HoursAvailable"

const Base = () => {
    
    const navigate = useNavigate()
    const networkConnected = useStore(state => state.networkConnected)
    const photos = useStore(state => state.photos)
    const appAssets = useAssetsStore(state => state.data)
    
    const onHistoryBtnClick = () => {
        navigate("/history")
    }
    
    const onMenuBtnClick = () => {
        navigate("/menu")
    }
    
    return (
        <div
            className="
                block
                w-full
                h-full
                overflow-auto
                pb-[50px]
                relative
                bg-[#000000]
                scrollbar-hidden
            "
        >
            <div className="
                block
                w-[94%]
                mx-auto
                pt-[40px]
                pb-[30px]
            ">
                <div className="
                    block
                    w-full
                    relative
                    pr-[100px]
                ">
                    <h1 className="
                        block
                        w-full
                        font-defaultBlack
                        text-left
                        text-[#ffffff]
                        text-[28px]
                        2xs:text-[32px]
                        leading-[32px]
                        2xs:leading-[35px]
                    "><span className="text-[#cc0000]">False</span> 9</h1>
                    <h2 className="
                        block
                        w-full
                        font-defaultRegular
                        text-left
                        text-[#dddddd]
                        text-[20px]
                        2xs:text-[23px]
                        leading-[22px]
                        2xs:leading-[26px]
                    ">Saikot</h2>
                    <button type="button" onClick={onHistoryBtnClick} className="
                        block
                        w-[50px]
                        h-[50px]
                        active:bg-[#222222]
                        absolute
                        top-0
                        right-[50px]
                        p-[10px]
                    ">
                        <HistoryIcon color="#ffffff"/>
                    </button>
                    <button type="button" onClick={onMenuBtnClick} className="
                        block
                        w-[50px]
                        h-[50px]
                        active:bg-[#222222]
                        absolute
                        top-0
                        right-0
                    ">
                        <span className="
                            block
                            w-[20px]
                            h-[2px]
                            bg-[#ffffff]
                            mx-auto
                            my-[5px]
                        "></span>
                        <span className="
                            block
                            w-[20px]
                            h-[2px]
                            bg-[#ffffff]
                            mx-auto
                            my-[5px]
                        "></span>
                        <span className="
                            block
                            w-[20px]
                            h-[2px]
                            bg-[#ffffff]
                            mx-auto
                            my-[5px]
                        "></span>
                    </button>
                </div>
                <Link to="/booking" className="
                    block
                    w-full
                    h-[55px]
                    2xs:h-[60px]
                    text-[#ffffff]
                    bg-[#6c09c9]
                    active:bg-[#8a2be2]
                    rounded-[8px]
                    mt-[30px]
                    relative
                    pr-[50px]
                ">
                    <div className="
                        block
                        w-full
                        absolute
                        top-1/2
                        -translate-y-1/2
                        left-0
                        px-[15px]
                    ">
                        <div className="
                            block
                            w-full
                            font-defaultRegular
                            text-left
                            text-[14px]
                            2xs:text-[16px]
                            uppercase
                        ">Book Now</div>
                    </div>
                    <div className="
                        block
                        w-[20px]
                        2xs:w-[25px]
                        h-[20px]
                        2xs:h-[25px]
                        absolute
                        top-1/2
                        -translate-y-1/2
                        right-[15px]
                    "><LongRightArrow color="#ffffff"/></div>
                </Link>
            </div>
            <div className="
                block
                w-full
                pt-[20px]
                pb-[30px]
            ">
                <div className="
                    block
                    w-full
                ">
                    <h2 className={`
                        block
                        w-[94%]
                        mx-auto
                        font-defaultBlack
                        text-left
                        text-[#ffffff]
                        text-[20px]
                        2xs:text-[24px]
                        relative
                        ${(networkConnected && appAssets && appAssets.available) ? "pl-[23px] 2xs:pl-[25px]" : ""}
                    `}>
                        {
                            (networkConnected && appAssets && appAssets.available) ?
                            <span className="
                                block
                                w-[13px]
                                2xs:w-[15px]
                                h-[13px]
                                2xs:h-[15px]
                                rounded-[50%]
                                bg-[#ff0000]
                                mr-[10px]
                                absolute
                                top-1/2
                                -translate-y-1/2
                                left-0
                                pulse-dot
                            "></span> : ""
                        }
                        Availablity
                        <Link to="/booking" className="text-[11px] 2xs:text-[12px] absolute top-1/2 -translate-y-1/2 right-0 text-[#888888] active:text-[#ffa500] font-defaultRegular uppercase">View More</Link>
                    </h2>
                    <h3 className={`
                        block
                        w-[94%]
                        mx-auto
                        font-defaultRegular
                        text-left
                        text-[#888888]
                        text-[10px]
                        2xs:text-[12px]
                        mb-[20px]
                        ${(networkConnected && appAssets && appAssets.available) ? "pl-[23px] 2xs:pl-[25px]" : ""}
                    `}>Next Three Days</h3>
                    {
                        appAssets ?
                        <>
                            {
                                appAssets.available ?
                                <div className="
                                    block
                                    w-full
                                    whitespace-nowrap
                                    overflow-auto
                                    scrollbar-hidden
                                ">
                                    <div className="
                                        grid
                                        grid-cols-3
                                        gap-[8px]
                                        2xs:gap-[10px]
                                        w-[250%]
                                        sm:w-[200%]
                                        xl:w-full
                                        px-[3%]
                                    ">
                                        <HoursAvailable date={
                                            new Date().getHours() >= 12 ?
                                            new Date(Date.now()+86400000) :
                                            new Date()
                                        }/>
                                        <HoursAvailable date={
                                            new Date().getHours() >= 12 ?
                                            new Date(Date.now()+172800000) :
                                            new Date(Date.now()+86400000)
                                        }/>
                                        <HoursAvailable date={
                                            new Date().getHours() >= 12 ?
                                            new Date(Date.now()+259200000) :
                                            new Date(Date.now()+172800000)
                                        }/>
                                    </div>
                                </div> :
                                <div className="
                                    block
                                    w-full
                                    py-[50px]
                                    bg-[#111111]
                                ">
                                    <div className="
                                        block
                                        w-full
                                        font-defaultBold
                                        text-center
                                        text-[#888888]
                                        text-[14px]
                                        2xs:text-[16px]
                                    ">Booking is temporarily unavailable,<br/> Please come back later.</div>
                                </div>
                            }
                        </> :
                        <div className="
                            block
                            w-[94%]
                            h-[200px]
                            mx-auto
                            rounded-[8px]
                            bg-[#111111]
                        ">
                            <div className="div-loading"></div>
                        </div>
                    }
                </div>
            </div>
            <div className="
                block
                w-full
                pt-[20px]
                pb-[30px]
                overflow-hidden
            ">
                <h2 className="
                    block
                    w-[94%]
                    mx-auto
                    font-defaultBlack
                    text-left
                    text-[#ffffff]
                    text-[20px]
                    2xs:text-[24px]
                    mb-[20px]
                    relative
                ">Photo Gallery <Link to={(!photos.loading && photos.data.length < 1) ? "/gallery?upload-photos" : "/gallery"} className="text-[11px] 2xs:text-[12px] absolute top-1/2 -translate-y-1/2 right-0 text-[#888888] active:text-[#ffa500] font-defaultRegular uppercase">
                    {
                        (!photos.loading && photos.data.length < 1) ?
                        "Upload Photos" :
                        "View All Photos"
                    }
                </Link></h2>
                {
                    photos.loading ?
                    <div className="
                        block
                        w-[94%]
                        mx-auto
                        h-[140px]
                        rounded-[10px]
                        bg-[#111111]
                    ">
                        <div className="div-loading"></div>
                    </div> :
                    photos.data.length ?
                    <div className="
                        block
                        xl:grid
                        grid-cols-6
                        gap-[10px]
                        w-full
                        relative
                        overflow-auto
                        whitespace-nowrap
                        scrollbar-hidden
                        px-[3%]
                    ">
                        {
                            photos.data.map((photo, i) => {
                                if (i > 11) return ""
                                
                                return (
                                    <Link to={`/gallery?go-to-photo=${i}`} key={photo.id} className="
                                        inline-block
                                        xl:block
                                        align-top
                                        w-[140px]
                                        xl:w-full
                                        square
                                        bg-no-repeat
                                        bg-cover
                                        bg-center
                                        ml-[10px]
                                        xl:ml-0
                                        first:ml-0
                                        rounded-[10px]
                                        xl:rounded-[0]
                                        relative
                                        overflow-hidden
                                    " style={{
                                        backgroundImage: `url(${photo.thumbnail_url || photo.url})`,
                                        backgroundColor: "#191919"
                                    }}></Link>
                                )
                            })
                        }
                    </div> :
                    <div className="
                        flex
                        w-[94%]
                        mx-auto
                        h-[140px]
                        rounded-[10px]
                        bg-[#111111]
                    ">
                        <div className="
                            block
                            w-[94%]
                            font-defaultBold
                            text-center
                            text-[#ffffff]
                            text-[14px]
                            2xs:text-[16px]
                            m-auto
                        ">
                            {
                                photos.data.error ?
                                photos.data.error.message :
                                "Nothing to see yet."
                            }
                        </div>
                    </div>
                }
            </div>
            <div className="
                block
                w-full
                pt-[20px]
            ">
                <div className="
                    block
                    w-[94%]
                    mx-auto
                ">
                    <Pricing/>
                </div>
            </div>
        </div>
    )

}

export default Base