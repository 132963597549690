import { useState, useEffect, useRef } from "react"
import dayjs from "dayjs"
import isToday from "dayjs/plugin/isToday"
import isTomorrow from "dayjs/plugin/isTomorrow"
import { useNavigate } from "react-router-dom"
import { onSnapshot, collection, query, where, limit } from "firebase/firestore"
import { db } from "../firebaseInit"
import { useStore, useAssetsStore } from "../store"
import hourConverter from "../lib/24HourTo12Hour"
import ChevronRight from "./icons/ChevronRight"

dayjs.extend(isToday)
dayjs.extend(isTomorrow)

const HoursAvailable = ({date}) => {

    const navigate = useNavigate()
    const [ hours, setHours ] = useState([])
    const [ isHoliday, setIsHoliday ] = useState(false)
    const [ dateId, setDateId ] = useState("")
    const setSelectedDate = useStore(state => state.setSelectedDate)
    const appAssets = useAssetsStore(state => state.data)
    const unsubscribeHoursWatcher = useRef(null)
    const unsubscribeHolidayWatcher = useRef(null)

    const onClick = () => {
        if (
            !date ||
            !appAssets ||
            (appAssets.sundayIsHoliday && date.getDay() === 0) ||
            isHoliday
        ) return
        
        setSelectedDate(date)
        navigate("/booking")
    }
    
    useEffect(() => {
        if (appAssets){
            const did = `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`
            setDateId(did)

            try {
                if (unsubscribeHoursWatcher.current){
                    unsubscribeHoursWatcher.current()
                }
                
                const q = query(collection(db, "bookings"), where("status", "!=", "cancelled"), where("dateId", "==", did), limit(24))
                unsubscribeHoursWatcher.current = onSnapshot(q, snapshot => {
                    const hrs = []
                    appAssets.pricing.hours.forEach(hr => {
                        let ua = false
                        snapshot.forEach(doc => {
                            if (!ua && doc.data().hour === hr.hour){
                                ua = true
                            }
                        })
                        const now = new Date()
                        const todayId = `${now.getDate()}-${now.getMonth()+1}-${now.getFullYear()}`
                        if (did === todayId && now.getHours() >= hr.hour){
                            ua = true
                        }
                        hrs.push({
                            id: `${hr.hour}-${did}`,
                            hour: hr.hour,
                            price: hr.price,
                            ua,
                            did
                        })
                    })
                    setHours(hrs)
                })
            }
            catch {
                setHours([])
            }
        }
    }, [appAssets, date])

    useEffect(() => {
        if (dateId){
            try {
                if (unsubscribeHolidayWatcher.current){
                    unsubscribeHolidayWatcher.current()
                }
                
                const q = query(collection(db, "holidays"), where("dateId", "==", dateId), limit(1))
                unsubscribeHolidayWatcher.current = onSnapshot(q, snapshot => {
                    if (snapshot.empty){
                        setIsHoliday(false)
                    }
                    else {
                        setIsHoliday(true)
                    }
                })
            }
            catch {
                setIsHoliday(false)
            }
        }
    }, [dateId])
    
    return (
        <div onClick={onClick} className={`
            w-full
            min-h-[200px]
            overflow-hidden
            relative
            rounded-[10px]
            bg-[#111111]
            border-[5px]
            border-solid
            border-[#1c1c1c]
            p-[2px]
        `}>
            <div className={`
                block
                w-full
                bg-[#1c1c1c]
                rounded-[5px]
                mb-[2px]
                pl-[15px]
                ${((appAssets.sundayIsHoliday && date.getDay() === 0) || isHoliday) ? "pr-[15px]" : "pr-[40px]"}
                relative
            `} style={{
                paddingTop: "18px",
                paddingBottom: "20px"
            }}>
                <h3 className="
                    block
                    w-full
                    font-defaultBlack
                    text-left
                    text-[#ffffff]
                    text-[12px]
                    2xs:text-[14px]
                    uppercase
                    relative
                ">
                    {dayjs(date).isTomorrow() ? "Tomorrow" : dayjs(date).isToday() ? "Today" : dayjs(date).format("dddd")}
                    <span className="
                        hidden
                        3xs:inline-block
                        font-defaultRegular
                        text-[#888888]
                        text-[9px]
                        absolute
                        top-1/2
                        -translate-y-1/2
                        right-0
                    ">{dayjs(date).format("LL")}</span>
                </h3>
                {
                    ((appAssets.sundayIsHoliday && date.getDay() === 0) || isHoliday) ?
                    "" :
                    <div className="
                        block
                        w-[16px]
                        h-[16px]
                        absolute
                        top-1/2
                        -translate-y-1/2
                        right-[15px]
                    "><ChevronRight color="#ffffff"/></div>
                }
            </div>
            {
                (appAssets && appAssets.sundayIsHoliday && date.getDay() === 0) ?
                <div className="
                    block
                    w-full
                    h-[150px]
                    rounded-[5px]
                    overflow-hidden
                    bg-[#111111]
                ">
                    <div className="
                        block
                        w-full
                        font-defaultBold
                        text-center
                        text-[#888888]
                        text-[14px]
                        2xs:text-[16px]
                        capitalize
                        relative
                        top-1/2
                        -translate-y-1/2
                        left-0
                        whitespace-pre-line
                        px-[20px]
                    ">Booking is closed for sundays</div>
                </div> :
                (isHoliday && date) ?
                <div className="
                    block
                    w-full
                    h-[150px]
                    rounded-[5px]
                    overflow-hidden
                    bg-[#111111]
                ">
                    <div className="
                        block
                        w-full
                        font-defaultBold
                        text-center
                        text-[#888888]
                        text-[14px]
                        2xs:text-[16px]
                        capitalize
                        relative
                        top-1/2
                        -translate-y-1/2
                        left-0
                        whitespace-pre-line
                        px-[20px]
                    ">Booking is closed for {dayjs(date).isToday() ? "Today" : dayjs(date).isTomorrow() ? "Tomorrow" : `${dayjs(date).format("LL")}`}</div>
                </div> :
                hours.length ?
                <div className="
                    block
                    w-full
                    whitespace-pre-line
                ">
                    <div className="
                        grid
                        grid-cols-3
                        3xs:grid-cols-4
                        xs:grid-cols-5
                        gap-[2px]
                    ">
                        {
                            hours.map(hour => {
                                const convertedHour = hourConverter(hour.hour)
                                return (
                                    <div key={hour.id} className={`
                                        w-full
                                        py-[5px]
                                        ${hour.ua ? "bg-[#191919]" : "bg-[#1c1c1c]"}
                                        rounded-[5px]
                                    `}>
                                        <div className={`
                                            block
                                            w-full
                                            font-defaultBlack
                                            text-center
                                            ${hour.ua ? "text-[#cd5c5c]" : "text-[#ffffff]"}
                                            text-[14px]
                                            2xs:text-[16px]
                                            xl:text-[14px]
                                            uppercase
                                        `}>
                                            {convertedHour.hour < 10 ? "0" : ""}
                                            {convertedHour.hour}
                                            <span className="
                                                inline-block
                                                ml-[3px]
                                                text-[55%]
                                                font-defaultRegular
                                            ">{convertedHour.ampm}</span>
                                        </div>
                                        <div className={`
                                            block
                                            w-full
                                            font-defaultRegular
                                            text-center
                                            ${hour.ua ? "text-[#444444]" : "text-[#888888]"}
                                            text-[8px]
                                            2xs:text-[10px]
                                            capitalize
                                        `}>{hour.ua ? "U/A" : `₹${hour.price}`}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div> :
                <div className="
                    block
                    w-full
                    h-[150px]
                    rounded-[5px]
                    overflow-hidden
                    bg-[#111111]
                ">
                    <div className="
                        block
                        div-loading
                    "></div>
                </div>
            }
        </div>
    )

}

export default HoursAvailable