import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"
import { getAuth } from "firebase/auth"

const firebaseConfig = {
    apiKey: "AIzaSyAWL86y_sVzlW2rnqO0YMsP2BbraNTpUnQ",
    authDomain: "false-9-saikot.firebaseapp.com",
    projectId: "false-9-saikot",
    storageBucket: "false-9-saikot.appspot.com",
    messagingSenderId: "170779196571",
    appId: "1:170779196571:web:59d6928629f5b5f8ad5a3e"
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const fbAuth = getAuth(app)

export { db, fbAuth }