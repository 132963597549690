const Icon = ({color}) => {

    return (
        <svg viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-full">
            <path d="M405.333 64H106.667C83.1025 64 64 83.1025 64 106.667V405.333C64 428.897 83.1025 448 106.667 448H405.333C428.897 448 448 428.897 448 405.333V106.667C448 83.1025 428.897 64 405.333 64Z" stroke={color || "#111111"} strokeWidth="30" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M181.333 213.333C199.006 213.333 213.333 199.006 213.333 181.333C213.333 163.66 199.006 149.333 181.333 149.333C163.66 149.333 149.333 163.66 149.333 181.333C149.333 199.006 163.66 213.333 181.333 213.333Z" fill={color || "#111111"}/>
            <path d="M453 310L341.333 213.333L106.667 448" fill={color || "#111111"}/>
            <path d="M438 437L448 305.5L111 444" fill={color || "#111111"}/>
        </svg>
    )

}

export default Icon