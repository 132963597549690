import { useState, useEffect, useRef, useCallback } from "react"
import QuickPinchZoom, { make3dTransformValue } from "react-quick-pinch-zoom"
import ChevronLeft from "./icons/ChevronLeft"
import ChevronRight from "./icons/ChevronRight"
import LeftArrow from "./icons/LeftArrow"
import "../styles/Photo.css"

const Photo = ({
    data,
    currentPhoto,
    setCurrentPhoto,
    getMoreData
}) => {

    const [ readyToLoadHighResPhoto, setReadyToLoadHighResPhoto ] = useState(false)
    const imgRef = useRef()

    const onUpdate = useCallback(({x, y, scale}) => {
        if (imgRef.current) {
            const value = make3dTransformValue({ x, y, scale })
            
            imgRef.current.style.setProperty("transform", value)
        }
    }, [])
    
    const prevPhoto = () => {
        if (currentPhoto > 0){
            setCurrentPhoto(currentPhoto-1)
        }
    }
    
    const nextPhoto = () => {
        if (currentPhoto < (data.data.length-1)){
            setCurrentPhoto(currentPhoto+1)

            if (data.data.length >= 50 && currentPhoto >= (data.data.length-5)){
                getMoreData()
            }
        }
    }

    useEffect(() => {
        if (imgRef.current){
            imgRef.current.style.setProperty("transform", "scale3d(1,1,1) translate3d(0px,0px,0)")
        }
    }, [currentPhoto])

    useEffect(() => {
        setReadyToLoadHighResPhoto(true)
    }, [])
    
    return (
        <div className="
            block
            w-full
            h-full
            absolute
            z-[30]
            top-0
            left-0
            bg-[#000000]
        ">
            {
                data.data[currentPhoto] ?
                <>
                    <div className="
                        block
                        w-full
                        h-[50px]
                        absolute
                        z-[20]
                        top-0
                        left-0
                    ">
                        <div className="
                            block
                            w-[94%]
                            mx-auto
                            relative
                        ">
                            <button type="button" className="
                                block
                                w-[50px]
                                h-[50px]
                                active:bg-[rgba(255,255,255,.2)]
                                p-[15px]
                            " onClick={() => window.history.back()}><LeftArrow color="#ffffff"/></button>
                        </div>
                    </div>
                    <QuickPinchZoom onUpdate={onUpdate}>
                        <div ref={imgRef} className="
                            block
                            w-full
                            h-full
                            bg-contain
                            bg-center
                            bg-no-repeat
                            relative
                            z-[10]
                        " style={{backgroundImage: `url(${data.data[currentPhoto].thumbnail_url || data.data[currentPhoto].url})`}}>
                            {
                                readyToLoadHighResPhoto ?
                                <div className="
                                    block
                                    w-full
                                    h-full
                                    bg-contain
                                    bg-center
                                    bg-no-repeat
                                " style={{backgroundImage: `url(${data.data[currentPhoto].url})`}}></div> : ""
                            }
                        </div>
                    </QuickPinchZoom>
                    <div className="
                        block
                        w-full
                        h-[100px]
                        absolute
                        z-[20]
                        bottom-0
                        left-0
                    ">
                        <div className="
                            block
                            w-[94%]
                            mx-auto
                            relative
                        ">
                            {
                                currentPhoto > 0 ?
                                <button type="button" className="
                                    block
                                    w-[60px]
                                    h-[60px]
                                    rounded-[50%]
                                    bg-[rgba(255,255,255,.2)]
                                    active:bg-[rgba(255,255,255,.4)]
                                    p-[20px]
                                " onClick={prevPhoto}><ChevronLeft color="#aaaaaa"/></button> : ""
                            }
                            {
                                currentPhoto < (data.data.length-1) ?
                                <button type="button" className="
                                    block
                                    w-[60px]
                                    h-[60px]
                                    rounded-[50%]
                                    bg-[rgba(255,255,255,.2)]
                                    active:bg-[rgba(255,255,255,.4)]
                                    p-[20px]
                                    absolute
                                    top-0
                                    right-0
                                " onClick={nextPhoto}><ChevronRight color="#aaaaaa"/></button> : ""
                            }
                        </div>
                    </div>
                </> : ""
            }
        </div>
    )

}

export default Photo