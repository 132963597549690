import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { useStore, useUserStore } from "../store"

const StatusBarHandler = () => {

    const location = useLocation()
    const locationQueries = useStore(state => state.locationQueries)
    const networkConnected = useStore(state => state.networkConnected)
    const userData = useUserStore(state => state.userData)
    
    useEffect(() => {
        if (!networkConnected){
            window.document.querySelector("meta[name='theme-color']").setAttribute("content", "#ff0000")
        }
        else {
            if (
                location.pathname === "/booking" ||
                (location.pathname === "/my-photos" && !locationQueries.includes("photo")) ||
                locationQueries.includes("upload-photos") ||
                ((location.pathname === "/checkout" || location.pathname === "/history") && userData)
            ){
                window.document.querySelector("meta[name='theme-color']").setAttribute("content", "#111111")
            }
            else {
                window.document.querySelector("meta[name='theme-color']").setAttribute("content", "#000000")
            }
        }
    }, [networkConnected, location.pathname, locationQueries, userData])
    
    return (
        <div className="hidden"></div>
    )

}

export default StatusBarHandler