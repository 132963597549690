import { Link } from "react-router-dom"
import { useStore, useAssetsStore } from "../store"
import HourSelector from "../components/HourSelector"
import LeftArrow from "../components/icons/LeftArrow"
import CalendarIcon from "../components/icons/Calendar"
import ChevronDown from "../components/icons/ChevronDown"
import LongRightArrow from "../components/icons/LongRightArrow"
import dateDisplay from "../lib/dateDisplay"
import SpinnerLight from "../images/spinner-light.gif"

const Booking = () => {

    const locationQueries = useStore(state => state.locationQueries)
    const selectedDate = useStore(state => state.selectedDate)
    const hoursForService = useStore(state => state.hoursForService)
    const selectedHours = useStore(state => state.selectedHours)
    const totalPrice = useStore(state => state.totalPrice)
    const appAssets = useAssetsStore(state => state.data)
    
    return (
        <div className={`
            block
            w-full
            h-full
            overflow-hidden
            bg-[#000000]
            absolute
            z-[20]
            top-0
            left-0
            pt-[50px]
            ${selectedHours.length > 0 ? "pb-[100px] 2xs:pb-[110px]" : ""}
        `}>
            <div className={`
                block
                w-full
                h-[50px]
                overflow-hidden
                bg-[#181818]
                absolute
                z-[20]
                top-0
                left-0
                ${locationQueries.includes("date-selector") ? "blur-[8px]" : "blur-none"}
            `}>
                <div className="
                    block
                    w-[94%]
                    h-full
                    mx-auto
                    relative
                ">
                    <button type="button" className="
                        block
                        w-[50px]
                        h-[50px]
                        active:bg-[rgba(255,255,255,.1)]
                        p-[15px]
                        absolute
                        top-1/2
                        -translate-y-1/2
                        -left-[10px]
                    " onClick={() => window.history.back()}>
                        <LeftArrow color="#ffffff"/>
                    </button>
                    <h2 className="
                        block
                        w-[94%]
                        mx-auto
                        font-defaultRegular
                        text-center
                        text-[#ffffff]
                        text-[18px]
                        2xs:text-[20px]
                        leading-[50px]
                    ">Select Hours</h2>
                </div>
            </div>
            {
                appAssets.available ?
                <>
                    <div className="
                        block
                        w-full
                        h-full
                        overflow-auto
                        py-[40px]
                        relative
                        z-[10]
                    ">
                        <div className={`
                            block
                            w-[94%]
                            mx-auto
                            ${locationQueries.includes("date-selector") ? "blur-[8px]" : "blur-none"}
                            scrollbar-hidden
                        `}>
                            {
                                selectedDate ?
                                <Link to="?date-selector" className="
                                    block
                                    w-full
                                    relative
                                    px-[50px]
                                    py-[15px]
                                    2xs:py-[18px]
                                    overflow-visible
                                    border
                                    border-solid
                                    border-[#333333]
                                    bg-[#191919]
                                    active:bg-[#222222]
                                    rounded-[10px]
                                ">
                                    <div className="
                                        block
                                        w-[35px]
                                        h-[35px]
                                        p-[5px]
                                        absolute
                                        top-1/2
                                        -translate-y-1/2
                                        left-[5px]
                                    "><CalendarIcon color="#87ceeb"/></div>
                                    <div className="
                                        block
                                        w-full
                                        font-defaultRegular
                                        text-left
                                        text-[#ffffff]
                                        text-[14px]
                                        2xs:text-[16px]
                                    ">{dateDisplay(selectedDate)}</div>
                                    <div className="
                                        block
                                        w-[40px]
                                        h-[40px]
                                        p-[12px]
                                        absolute
                                        top-1/2
                                        -translate-y-1/2
                                        right-[5px]
                                    "><ChevronDown color="#87ceeb"/></div>
                                </Link> :
                                <div className="
                                    block
                                    w-full
                                    h-[60px]
                                    bg-[#222222]
                                    rounded-[10px]
                                    overflow-hidden
                                ">
                                    <div className="div-loading"></div>
                                </div>
                            }
                            <div className="
                                block
                                w-full
                                mt-[20px]
                            ">
                                {
                                    hoursForService.loading ?
                                    <div className="
                                        block
                                        w-full
                                        h-[200px]
                                        relative
                                    ">
                                        <img src={SpinnerLight} alt="" className="
                                            block
                                            w-[40px]
                                            absolute
                                            top-1/2
                                            -translate-y-1/2
                                            left-1/2
                                            -translate-x-1/2
                                        "/>
                                    </div> :
                                    (!hoursForService.loading && hoursForService.data) ?
                                    <div className="
                                        block
                                        w-full
                                    ">
                                        <HourSelector/>
                                    </div> :
                                    ""
                                }
                            </div>
                        </div>
                    </div>
                    {
                        (!hoursForService.loading && hoursForService.data) ?
                        <div className={`
                            block
                            w-full
                            h-[100px]
                            2xs:h-[110px]
                            bg-[#000000]
                            absolute
                            z-[20]
                            ${selectedHours.length > 0 ? "bottom-0" : "-bottom-[150px]"}
                            left-0
                            duration-[.2s]
                            ease-in-out
                        `}>
                            <div className="
                                block
                                w-[94%]
                                mx-auto
                                py-[10px]
                            ">
                                <Link to="/checkout" className="
                                    block
                                    w-full
                                    h-[55px]
                                    2xs:h-[60px]
                                    bg-[#6c09c9]
                                    active:bg-[#8a2be2]
                                    rounded-[10px]
                                    relative
                                ">
                                    <div className="
                                        block
                                        w-full
                                        absolute
                                        top-1/2
                                        -translate-y-1/2
                                        left-0
                                        pl-[15px]
                                    ">
                                        <div className="
                                            block
                                            w-full
                                            font-defaultRegular
                                            text-left
                                            text-[14px]
                                            2xs:text-[16px]
                                            text-[#ffffff]
                                            uppercase
                                        ">Continue</div>
                                        <div className="
                                            block
                                            w-full
                                            font-defaultRegular
                                            text-left
                                            text-[8px]
                                            2xs:text-[10px]
                                            text-[#dddddd]
                                        ">{selectedHours.length} Hour{selectedHours.length > 1 ? "s" : ""}/₹{totalPrice}</div>
                                    </div>
                                    <div className="
                                        block
                                        w-[20px]
                                        2xs:w-[25px]
                                        h-[20px]
                                        2xs:h-[25px]
                                        absolute
                                        top-1/2
                                        -translate-y-1/2
                                        right-[15px]
                                    "><LongRightArrow color="#ffffff"/></div>
                                </Link>
                            </div>
                        </div> : ""
                    }
                </> :
                <div className="
                    block
                    w-[90%]
                    mx-auto
                    py-[50px]
                ">
                    <div className="
                        block
                        w-full
                        font-defaultBold
                        text-center
                        text-[#888888]
                        text-[14px]
                        2xs:text-[16px]
                    ">Booking is temporarily unavailable,<br/> Please come back later.</div>
                </div>
            }
        </div>
    )

}

export default Booking