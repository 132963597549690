const Icon = ({color}) => {

    return (
        <svg viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-full">
            <path d="M405.333 85.3335H106.667C83.1025 85.3335 64 104.436 64 128V426.667C64 450.231 83.1025 469.333 106.667 469.333H405.333C428.897 469.333 448 450.231 448 426.667V128C448 104.436 428.897 85.3335 405.333 85.3335Z" stroke={color || "#111111"} strokeWidth="40" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M341.333 42.6665V128" stroke={color || "#111111"} strokeWidth="40" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M170.667 42.6665V128" stroke={color || "#111111"} strokeWidth="40" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M64 213.333H448" stroke={color || "#111111"} strokeWidth="40" strokeLinecap="round" strokeLinejoin="round"/>
            <rect x="116" y="276" width="40" height="40" fill={color || "#111111"}/>
            <rect x="196" y="276" width="40" height="40" fill={color || "#111111"}/>
            <rect x="276" y="276" width="40" height="40" fill={color || "#111111"}/>
            <rect x="356" y="276" width="40" height="40" fill={color || "#111111"}/>
            <rect x="116" y="356" width="40" height="40" fill={color || "#111111"}/>
            <rect x="196" y="356" width="40" height="40" fill={color || "#111111"}/>
            <rect x="276" y="356" width="40" height="40" fill={color || "#111111"}/>
        </svg>
    )

}

export default Icon