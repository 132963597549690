import { useState, useEffect, useRef } from "react"
import { updateProfile } from "firebase/auth"
import TextareaAutosize from "react-textarea-autosize"
import { Toast } from "@capacitor/toast"
import { Haptics } from "@capacitor/haptics"
import { fbAuth } from "../firebaseInit"
import { useStore, useUserStore } from "../store"

const NameEditor = () => {
    
    const [ name, setName ] = useState("")
    const [ prefilled, setPrefilled ] = useState(false)
    const somethingIsLoading = useStore(state => state.somethingIsLoading)
    const setSomethingIsLoading = useStore(state => state.setSomethingIsLoading)
    const userData = useUserStore(state => state.userData)
    const nameInputRef = useRef(null)
    
    const onNameInputChange = e => {
        const n = e.target.value.replace(/(\r\n|\n|\r)/gm, "")
        if (n.length <= 128){
            setName(n)
        }
    }
    
    const saveName = async () => {
        if (!fbAuth.currentUser || somethingIsLoading) return

        if (!name){
            await Toast.show({
                text: "Please enter your name.",
                duration: "long",
                position: "bottom"
            })
            await Haptics.notification({
                type: "ERROR"
            })
            return
        }
        else if (name.length > 128){
            await Toast.show({
                text: "Name too long",
                duration: "long",
                position: "bottom"
            })
            await Haptics.notification({
                type: "ERROR"
            })
            return
        }
        
        setSomethingIsLoading(true)
        
        try {
            await updateProfile(fbAuth.currentUser, {
                displayName: name
            })
            setSomethingIsLoading(false)
            if (window.location.search.includes("edit-name")){
                window.history.back()
            }
        }
        catch (err){
            setSomethingIsLoading(false)
            await Toast.show({
                text: (err && err.message) ? err.message : "Something went wrong, please try again.",
                duration: "long",
                position: "bottom"
            })
            await Haptics.notification({
                type: "ERROR"
            })
        }
    }
    
    useEffect(() => {
        if (nameInputRef.current){
            nameInputRef.current.focus()
        }
    }, [])

    useEffect(() => {
        if (!prefilled && userData){
            setPrefilled(true)
            if (userData.displayName){
                setName(userData.displayName)
            }
        }
    }, [userData, prefilled])
    
    return (
        <div className="
            block
            w-full
            h-full
            overflow-hidden
            bg-[rgba(0,0,0,.9)]
            absolute
            z-[20]
            top-0
            left-0
        ">
            <div className="
                block
                w-full
                pt-[10px]
                pb-[30px]
                border-t
                border-solid
                border-[#333333]
                bg-[#111111]
                absolute
                bottom-0
                left-0
            ">
                <div className="
                    block
                    w-[94%]
                    mx-auto
                    relative
                ">
                    <TextareaAutosize
                        id="name-input"
                        placeholder="Enter Your Name"
                        name="name"
                        value={name}
                        onChange={onNameInputChange}
                        minRows={1}
                        maxRows={10}
                        ref={nameInputRef}
                        className="
                            block
                            w-full
                            min-h-[45px]
                            2xs:min-h-[50px]
                            border-b
                            border-solid
                            border-[#444444]
                            font-defaultBold
                            text-left
                            text-[#ffffff]
                            text-[16px]
                            2xs:text-[18px]
                            leading-[19px]
                            2xs:leading-[20px]
                            py-[13px]
                            2xs:py-[15px]
                            pr-[50px]
                            resize-none
                            relative
                            z-[20]
                        "
                    />
                    <div className="
                        block
                        w-[40px]
                        font-defaultRegular
                        text-right
                        text-[#888888]
                        text-[11px]
                        2xs:text-[12px]
                        leading-[45px]
                        2xs:leading-[50px]
                        absolute
                        z-[10]
                        top-0
                        right-0
                    ">{128-name.length}</div>
                </div>
                <div className="
                    block
                    w-[94%]
                    mx-auto
                    text-right
                    mt-[30px]
                ">
                    <label htmlFor="name-input" className="
                        inline-block
                        leading-[30px]
                        active:bg-[#222222]
                        font-defaultRegular
                        text-center
                        text-[#ffffff]
                        text-[14px]
                        2xs:text-[16px]
                        mr-[10px]
                        px-[10px]
                    " onClick={() => window.history.back()}>Cancel</label>
                    <label htmlFor="name-input" className="
                        inline-block
                        leading-[30px]
                        active:bg-[#222222]
                        font-defaultRegular
                        text-center
                        text-[#ffffff]
                        text-[14px]
                        2xs:text-[16px]
                        px-[10px]
                    " onClick={saveName}>Save</label>
                </div>
            </div>
        </div>
    )

}

export default NameEditor