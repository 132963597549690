import { useEffect } from "react"
import { Network } from "@capacitor/network"
import { useStore } from "../store"

const NetworkHandler = () => {

    const setNetworkConnected = useStore(state => state.setNetworkConnected)
    
    useEffect(() => {
        const getNetworkStatus = async () => {
            const networkStatus = await Network.getStatus()
            setNetworkConnected(networkStatus.connected)
            
            Network.addListener("networkStatusChange", status => {
                setNetworkConnected(status.connected)
            })
        }
        getNetworkStatus()
    }, [setNetworkConnected])
    
    return (
        <div className="hidden"></div>
    )

}

export default NetworkHandler