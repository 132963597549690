const Icon = ({color}) => {

    return (
        <svg viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M298.667 42.6667H128C116.684 42.6667 105.832 47.1619 97.83 55.1635C89.8285 63.165 85.3333 74.0174 85.3333 85.3334V426.667C85.3333 437.983 89.8285 448.835 97.83 456.837C105.832 464.838 116.684 469.333 128 469.333H384C395.316 469.333 406.168 464.838 414.17 456.837C422.171 448.835 426.667 437.983 426.667 426.667V170.667L298.667 42.6667Z" stroke={color || "#111111"} strokeWidth="40" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M298.667 42.6667V170.667H426.667" stroke={color || "#111111"} strokeWidth="40" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M341.333 277.333H170.667" stroke={color || "#111111"} strokeWidth="40" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M341.333 362.667H170.667" stroke={color || "#111111"} strokeWidth="40" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M213.333 192H192H170.667" stroke={color || "#111111"} strokeWidth="40" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )

}

export default Icon