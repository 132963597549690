import { useEffect } from "react"
import { useStore, useAssetsStore } from "../store"

const TotalPriceCalculator = () => {
    
    const setTotalPrice = useStore(state => state.setTotalPrice)
    const selectedHours = useStore(state => state.selectedHours)
    const appAssets = useAssetsStore(state => state.data)

    useEffect(() => {
        // calculate total price
        if (selectedHours.length > 0 && appAssets && appAssets.pricing){
            let total = 0
            selectedHours.forEach(sh => {
                const hour = Number(sh.split("-")[0])
                const price = appAssets.pricing.hours.filter(p => p.hour === hour)
                if (price[0]){
                    total += price[0].price
                }
            })
            setTotalPrice(total)
        }
        else {
            setTotalPrice(0)
        }
    }, [selectedHours, appAssets, setTotalPrice])

    return (
        <div className="hidden"></div>
    )

}

export default TotalPriceCalculator