import { useState, useEffect, useRef } from "react"
import Calendar from "react-calendar"
import { useStore, useAssetsStore } from "../store"
import datesOfMonth from "../lib/datesOfMonth"
import XIcon from "./icons/XIcon"
import ChevronLeft from "./icons/ChevronLeft"
import ChevronRight from "./icons/ChevronRight"
import SpinnerLight from "../images/spinner-light.gif"
import "../styles/Calendar.css"

const DateSelector = ({disablePastMonths}) => {

    const [ prevBtnActive, setPrevBtnActive ] = useState(disablePastMonths ? false : true)
    const initPrevBtnActive = useRef(false)
    const locationQueries = useStore(state => state.locationQueries)
    const selectedDate = useStore(state => state.selectedDate)
    const setSelectedDate = useStore(state => state.setSelectedDate)
    const holidays = useStore(state => state.holidays)
    const appAssets = useAssetsStore(state => state.data)
    const initDateSelected = useRef(false)
    
    const prevMonth = () => {
        if (!appAssets || !holidays) return

        if (prevBtnActive){
            const prevBtn = document.querySelector(".react-calendar__navigation__prev-button")
            prevBtn.click()
        }
    }
    
    const nextMonth = () => {
        if (!appAssets || !holidays) return

        const nextBtn = document.querySelector(".react-calendar__navigation__next-button")
        nextBtn.click()
    }

    useEffect(() => {
        if (selectedDate){
            if (initDateSelected.current){
                if (window.location.search.includes("date-selector")){
                    window.history.back()
                }
            }
            else {
                initDateSelected.current = true
            }
        }
    }, [selectedDate])

    useEffect(() => {
        if (selectedDate && !initPrevBtnActive.current){
            initPrevBtnActive.current = true
            const thisYear = new Date().getFullYear()
            const thisMonth = new Date().getMonth()
            const refMonthStart = new Date(thisYear, thisMonth)
            
            setPrevBtnActive(true)
            if (refMonthStart.getTime() >= selectedDate.getTime()){
                setPrevBtnActive(false)
            }
        }
    }, [selectedDate])
    
    useEffect(() => {
        if (holidays && appAssets){
            if (
                !selectedDate ||
                holidays.includes(`${selectedDate.getDate()}-${selectedDate.getMonth()+1}-${selectedDate.getFullYear()}`) ||
                (appAssets.sundayIsHoliday && selectedDate.getDay() === 0)
            ){
                const date = new Date(new Date().setHours(0,0,0,0))
                const thisMonth = date.getMonth()
                for (let i = thisMonth; i < 1000; i++){
                    const dom = datesOfMonth(new Date().setMonth(thisMonth,1))
                    let shouldBreak = false
                    for (let a = 0; a < dom.length; a++){
                        const d = dom[a]
                        const dateId = `${d.getDate()}-${d.getMonth()+1}-${d.getFullYear()}`
                        if (d.getTime() >= date.getTime() && !holidays.includes(dateId) && !(appAssets.sundayIsHoliday && d.getDay() === 0)){
                            setSelectedDate(d)
                            shouldBreak = true
                            break
                        }
                    }
                    if (shouldBreak){
                        break
                    }
                }
                if (!selectedDate){
                    setSelectedDate(new Date())
                }
            }
        }
    }, [holidays, selectedDate, setSelectedDate, appAssets])
    
    return (
        <div className={`
            block
            w-full
            h-full
            overflow-hidden
            absolute
            z-[30]
            ${locationQueries.includes("date-selector") ? "top-0" : "top-[110%]"}
            left-0
            duration-[.2s]
            ease-in-out
        `}>
            <div className="
                block
                w-full
                h-full
                absolute
                z-[10]
                top-0
                left-0
            " onClick={() => window.history.back()}></div>
            <div className="
                block
                w-full
                absolute
                z-[20]
                bottom-0
                left-0
                overflow-visible
                pb-[20px]
                bg-[#111111]
            ">
                <div className="
                    block
                    w-[94%]
                    h-0
                    overflow-visible
                    relative
                    mx-auto
                ">
                    <button type="button" className="
                        block
                        w-[40px]
                        2xs:w-[45px]
                        h-[40px]
                        2xs:h-[45px]
                        rounded-[50%]
                        bg-[#111111]
                        active:bg-[#222222]
                        border
                        border-solid
                        border-[#222222]
                        p-[12px]
                        2xs:p-[14px]
                        absolute
                        bottom-[10px]
                        left-0
                    " onClick={() => window.history.back()}><XIcon color="#ffffff"/></button>
                </div>
                <div className="
                    block
                    w-full
                    overflow-hidden
                    rounded-t-[30px]
                    bg-[#111111]
                    relative
                ">
                    {
                        prevBtnActive ?
                        <button type="button" className={`
                            block
                            w-[65px]
                            h-[65px]
                            absolute
                            z-[20]
                            top-0
                            left-0
                            p-[21px]
                            active:bg-[rgba(0,0,0,.1)]
                        `} onClick={prevMonth}><ChevronLeft color="#ffffff"/></button> : ""
                    }
                    <button type="button" className={`
                        block
                        w-[65px]
                        h-[65px]
                        absolute
                        z-[20]
                        top-0
                        right-0
                        p-[21px]
                        active:bg-[rgba(0,0,0,.1)]
                    `} onClick={nextMonth}><ChevronRight color="#ffffff"/></button>
                    {
                        (holidays && appAssets) ?
                        <Calendar
                            onChange={setSelectedDate}
                            value={selectedDate}
                            minDetail="month"
                            tileDisabled={holidays ? ({activeStartDate, date, view }) => {
                                const dateMilliseconds = new Date(date.setHours(0,0,0,0)).getTime()
                                const todayMilliseconds = new Date(new Date().setHours(0,0,0,0)).getTime()
                                
                                let isHoliday = false
                                for (let i = 0; i < holidays.length; i++){
                                    const dateId = `${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`
                                    if (dateId === holidays[i]){
                                        isHoliday = true
                                        break
                                    }
                                }

                                if (appAssets.sundayIsHoliday && date.getDay() === 0){
                                    isHoliday = true
                                }
                                
                                return dateMilliseconds < todayMilliseconds || isHoliday
                            } : () => false}
                            onActiveStartDateChange={disablePastMonths ? ({action, activeStartDate}) => {
                                const thisYear = new Date().getFullYear()
                                const thisMonth = new Date().getMonth()
                                const refMonthStart = new Date(thisYear, thisMonth)
                                
                                setPrevBtnActive(true)
                                if (refMonthStart.getTime() >= activeStartDate.getTime()){
                                    setPrevBtnActive(false)
                                }
                            } : null}
                        /> :
                        <div className="
                            block
                            w-full
                            h-[300px]
                            relative
                            bg-[#111111]
                        ">
                            <img src={SpinnerLight} alt="" className="
                                block
                                w-[30px]
                                absolute
                                top-1/2
                                -translate-y-1/2
                                left-1/2
                                -translate-x-1/2
                            "/>
                        </div>
                    }
                </div>
            </div>
        </div>
    )

}

export default DateSelector