import { useState, useEffect } from "react"
import { useAssetsStore } from "../store"
import hourConverter from "../lib/24HourTo12Hour"

const Pricing = () => {

    const [ pricing, setPricing ] = useState(null)
    const appAssets = useAssetsStore(state => state.data)
    
    useEffect(() => {
        if (appAssets){
            const category = []
            appAssets.pricing.hours.forEach(hour => {
                if (!category.includes(hour.price)){
                    category.push(hour.price)
                }
            })

            const p = []
            category.forEach(cat => {
                const hours = appAssets.pricing.hours.map(hour => {
                    if (hour.price === cat){
                        return hourConverter(hour.hour)
                    }
                    else {
                        return -1
                    }
                }).filter(hour => hour !== -1)
                
                p.push({
                    price: cat,
                    hours
                })
            })
            setPricing(p)
        }
    }, [appAssets])
    
    return (
        <div className="
            block
            w-full
        ">
            <h2 className="
                block
                w-full
                font-defaultBlack
                text-left
                text-[#ffffff]
                text-[20px]
                2xs:text-[24px]
                mb-[20px]
                relative
            ">Pricing</h2>
            {
                pricing ?
                <div className="
                    block
                    md:grid
                    md:grid-cols-2
                    w-full
                    bg-[#111111]
                    rounded-[10px]
                    border-[5px]
                    border-solid
                    border-[#1c1c1c]
                ">
                    {
                        pricing.map((p, i) => {
                            return (
                                <div key={i} className={`
                                    w-full
                                    min-h-[90px]
                                    pl-[110px]
                                    2xs:pl-[130px]
                                    relative
                                    border-b-[5px]
                                    md:border-b-0
                                    last:border-b-0
                                    ${i%2 ? "" : "md:border-r-[5px]"}
                                    ${
                                        (
                                            pricing.length > 2 &&
                                            (pricing.length%2 || i !== (pricing.length-2)) &&
                                            i !== (pricing.length-1)
                                        ) ?
                                        "md:border-b-[5px]" :
                                        ""
                                    }
                                    border-solid
                                    border-[#1c1c1c]
                                    p-[15px]
                                `}>
                                    <div className="
                                        block
                                        w-[110px]
                                        2xs:w-[130px]
                                        font-defaultBlack
                                        text-left
                                        text-[#ffffff]
                                        text-[20px]
                                        2xs:text-[23px]
                                        absolute
                                        top-0
                                        left-0
                                        p-[15px]
                                    ">
                                        ₹{p.price}
                                        <span className="
                                            block
                                            w-full
                                            text-[50%]
                                            font-defaultRegular
                                            text-[#87ceeb]
                                        ">Per Hour</span>
                                    </div>
                                    <div className="
                                        grid
                                        grid-cols-2
                                        3xs:grid-cols-3
                                        xs:grid-cols-4
                                        gap-[2px]
                                    ">
                                        {
                                            p.hours.map((hour, x) => {
                                                return (
                                                    <div key={x} className="
                                                        w-full
                                                        leading-[35px]
                                                        rounded-[5px]
                                                        font-defaultBlack
                                                        text-center
                                                        text-[#ffffff]
                                                        text-[14px]
                                                        2xs:text-[16px]
                                                        bg-[#1c1c1c]
                                                        uppercase
                                                    ">
                                                        {hour.hour < 10 ? "0" : ""}
                                                        {hour.hour}
                                                        <span className="
                                                            font-defaultRegular
                                                            text-[55%]
                                                            ml-[2px]
                                                        ">{hour.ampm}</span>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div> :
                <div className="
                    block
                    w-full
                ">
                    <div className="
                        block
                        w-full
                        h-[300px]
                        bg-[#111111]
                        rounded-[10px]
                        mb-[10px]
                        relative
                        overflow-hidden
                        border-[5px]
                        border-solid
                        border-[#191919]
                    "><div className="div-loading"></div></div>
                </div>
            }
        </div>
    )

}

export default Pricing