import { create } from "zustand"
import { persist } from "zustand/middleware"

const store = set => ({
    nameOfMonths: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ],
    
    networkConnected: true,
    setNetworkConnected: bool => set(() => ({
        networkConnected: bool
    })),
    
    somethingIsLoading: false,
    setSomethingIsLoading: bool => set(() => ({
        somethingIsLoading: bool
    })),

    locationQueries: [],
    setLocationQueries: data => set(() => ({
        locationQueries: data
    })),

    selectedDate: null,
    setSelectedDate: date => set(() => ({
        selectedDate: date
    })),

    holidays: null,
    setHolidays: data => set(() => ({
        holidays: data
    })),

    hoursForService: {
        loading: false,
        data: null
    },
    setHoursForService: data => set(storeData => ({
        hoursForService: {
            ...storeData.hoursForService,
            ...data
        }
    })),

    selectedHours: [],
    setSelectedHours: data => set(() => ({
        selectedHours: data
    })),

    totalPrice: 0,
    setTotalPrice: total => set(() => ({
        totalPrice: total
    })),

    myPhotos: {
        init: false,
        lastItem: null,
        error: null,
        loading: false,
        data: []
    },
    setMyPhotos: data => set(storeData => ({
        myPhotos: {
            ...storeData.myPhotos,
            ...data
        }
    })),
    resetMyPhotos: () => set(() => ({
        myPhotos: {
            init: false,
            lastItem: null,
            error: null,
            loading: false,
            data: []
        }
    })),

    photos: {
        init: false,
        lastItem: null,
        error: null,
        loading: false,
        data: []
    },
    setPhotos: data => set(storeData => ({
        photos: {
            ...storeData.photos,
            ...data
        }
    }))
})

const userStore = set => ({
    userData: null,
    setUserData: data => set(() => ({
        userData: data
    }))
})

const assetsStore = set => ({
    data: null,
    setData: data => set(() => ({data}))
})

const useStore = create(store)

const useUserStore = create(persist(userStore, {
    name: "user-data",
    getStorages: () => window.localStorage
}))

const useAssetsStore = create(persist(assetsStore, {
    name: "app-assets",
    getStorages: () => window.localStorage
}))

export { useStore, useUserStore, useAssetsStore }